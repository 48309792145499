import React, {Component} from 'react';

class NotFound extends Component{
    render(){
        return(
            <div>
                Pagina no encontrada
            </div>
        );
    }
}

export default NotFound;