export const getClientsResponseDummy = {
    "files": [
        {
            "id" : 1,
            "name" : "ignacio"
        },
        {
            "id" : 2,
            "name" : "thaly"
        }
    ],
    "total" : 15
}

export const getClientResponseDummy = {
    "id" : 1,
    "name" : "ignacio silva",
    "telephone" : "098591898",
    "address" : "Velsen 4422",
    "age" : 29,
    "ocupation" : "programador",
    "startDate" : "2022-05-17T09:30:12",
    "comments" : "3 muelas del juicio",
    "history" : [
        {
            "id" : 1,
            "date" : "2022-04-25T15:32:18",
            "description" : "Tratamiento de conducto",
            "price" : 1250.00,
            "payment" : 500.00
        },
        {
            "id" : 2,
            "date" : "2022-04-26T17:13:32",
            "description" : "Ortodoncia",
            "price" : 1500.00,
            "payment" : 1500.00
        }
    ]
}

export const postClientResponseDummy = {
    "id" : 1,
    "name" : "ignacio silva",
    "telephone" : "098591898",
    "address" : "Velsen 4422",
    "age" : 29,
    "ocupation" : "programador",
    "startDate" : "2022-05-17T09:30:12",
    "comments" : "3 muelas del juicio"
}

export const getHistoryResponseDummy = {
    "clientId" : 1,
    "date" : "2022-05-17T09:30:12",
    "description" : "segunda muela, paleta derecha",
    "treatment" : "tratamiento de conducto",
    "price" : 1550.00,
    "payment" : 1350.00,
    "teeth" : "1,3,5,6"
}

export const getFreeDatesResponseDummy = {
    "data": [
        {
            "date" : "2022-05-01",
            "description" : "feriado: dia del trabajador"
        },
        {
            "date" : "2022-05-08",
            "description" : "domingos no se trabaja"
        },
        {
            "date" : "2022-05-10",
            "description" : "consultorio san jose"
        },
        {
            "date" : "2022-05-12",
            "description" : "día completo"
        }
    ]
}
export const getTimesResponseDummy = {
    "data": [
        {
            "dateTime": "2022-05-02T09:00:00"
        },
        {
            "appointmentId": 13,
            "dateTime": "2022-05-02T09:30:00",
            "client": {
                "id": 1,
                "name": "ignacio silva",
                "address": "Velsen 4422",
                "age": 29,
                "occupation": "",
                "comments": "ninguna"
            }
        },
        {
            "dateTime": "2022-05-02T10:00:00"
        },
        {
            "dateTime": "2022-05-02T10:30:00"
        },
        {
            "dateTime": "2022-05-02T11:00:00"
        },
        {
            "dateTime": "2022-05-02T11:30:00"
        },
        {
            "dateTime": "2022-05-02T12:00:00"
        },
        {
            "dateTime": "2022-05-02T12:30:00"
        },
        {
            "dateTime": "2022-05-02T14:00:00"
        },
        {
            "dateTime": "2022-05-02T14:30:00"
        },
        {
            "dateTime": "2022-05-02T15:00:00"
        },
        {
            "dateTime": "2022-05-02T15:30:00"
        },
        {
            "dateTime": "2022-05-02T16:00:00"
        },
        {
            "dateTime": "2022-05-02T16:30:00"
        },
        {
            "dateTime": "2022-05-02T17:00:00"
        },
        {
            "dateTime": "2022-05-02T17:30:00"
        }
    ]
}

export const getFilesResponseDummy = {
    "data": [
        {
            "id" : 1,
            "name" : "ignacio"
        },
        {
            "id" : 2,
            "name" : "thaly"
        }
    ],
    "total" : 15
}

export const getConfigurationResponseDummy = {
    "version": 1
}