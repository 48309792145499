import React, {useEffect, useRef, useState} from 'react';
import BasicWrapper from "../../components/generic-wrapper";
import {useNavigate, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Alert from 'react-s-alert';
import TextInput from "../../components/input/text-input";
import {deleteHistory, getHistory, postHistory} from "../../services/history.service";
import DeleteButton from "../../components/generic-wrapper/delete-button";
import {toIsoStringNoMillis} from "../../util/date.util";
import SimpleModal from "../../components/modal";
import useFormData from "../../hooks/form-hook";
import {useIsMount} from "../../util/custom.hook";
import {getAccessToken} from "../../services/user.service";
import Teeth from "../../components/teeth";

export default function HistoryForm() {
    const isMount = useIsMount();
    let { clientId, historyId } = useParams();
    const loading = useRef();
    const modal = useRef();
    const navigate = useNavigate()
    const [id, setId] = useState(0);

    const {data : date, onDataChange: onDateChange} = useFormData('', value => {
        if (value.length === 0) return "La fecha no puede ser vacía"
        if (value.length > 0 && !value.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})/)) return "La fecha tiene un formato inválido"
    })

    const {data : description, onDataChange: onDescriptionChange} = useFormData('', value => {
        if (value.length > 256) return "El numero de diente no puede tener mas de 256 caracteres"
    })

    const {data : treatment, onDataChange: onTreatmentChange} = useFormData('', value => {
        //if (value.length === 0) return "El tratamiento no puede ser vacía"
    })

    const {data : price, onDataChange: onPriceChange} = useFormData(0, value => {
        if (value.length === 0) return "El precio no puede ser vacío"
        //if (value <= 0) return "El precio debe ser mayor o igual a cero"
    })

    const {data : payment, onDataChange: onPaymentChange} = useFormData(0, value => {
        if (value.length === 0) return "El pago no puede ser vacío"
        //if (value <= 0) return "El pago debe ser mayor o igual a cero"
    })

    const {data : teeth, onDataChange: onTeethChange} = useFormData("", value => {

    })

    const fetchData = () => {
        loading.current.show()
        getHistory(historyId)
            .then( r => {
                setId(r.id)
                onDateChange(r.date)
                onTreatmentChange(r.treatment)
                onDescriptionChange(r.description)
                onPriceChange(r.price)
                onPaymentChange(r.payment)
                onTeethChange(r.teeth)
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener la historia. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    const componentDidMount = () => {
        if (!getAccessToken()) {
            navigate("/login")
            return
        }
        if (historyId) {
            fetchData()
        } else {
            onDateChange(toIsoStringNoMillis(new Date()))
            onDescriptionChange("")
            onPriceChange(0)
            onPaymentChange(0)
            loading.current.hide()
        }
    }
    useEffect(componentDidMount, []);

    const onSubmit = () => {
        loading.current.show()
        postHistory(clientId, historyId, date.value, description.value, treatment.value, price.value, payment.value, teeth.value)
            .then( _ => {
                Alert.success("Historia guardada.");
                navigate(`/paciente/${clientId}`)
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar guardar la historia. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const onDeleteClick = () => modal.current.show()

    const onModalConfirm = () => {
        loading.current.show()
        deleteHistory(historyId)
            .then( r => {
                navigate(`/paciente/${clientId}`)
                Alert.success("Historia borrada.");
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar borrar la historia. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    return(
        <BasicWrapper ref={loading} title={(historyId ? "Editar" : "Nueva") + " Historia"} nav={<DeleteButton onDeleteClick={onDeleteClick}/>} >
            <div className="row">
                <div className="mb-2 col-md-3">
                    <Teeth value={teeth.value} onChange={onTeethChange}/>
                </div>
                <div className="mb-2 col-md-9">

                    <div className="row">
                        <div className="mb-2 col-md-3">
                            <TextInput type="datetime-local" label="Fecha*" value={date.value} onChange={onDateChange} valid={date.valid} errorMessage={date.errorMessage} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="mb-2 col-md-3">
                            <TextInput label="N° Diente" value={description.value} onChange={onDescriptionChange} valid={description.valid} errorMessage={description.errorMessage} />
                        </div>
                        <div className="mb-2 col-md-3">
                            <TextInput label="Tratamiento" type="textarea" value={treatment.value} onChange={onTreatmentChange} valid={treatment.valid} errorMessage={treatment.errorMessage} />
                        </div>
                        <div className="mb-2 col-md-3">
                            <TextInput type="number" label="Precio" value={price.value} onChange={onPriceChange} valid={price.valid} errorMessage={price.errorMessage} />
                        </div>
                        <div className="mb-2 col-md-3">
                            <TextInput type="number" label="Pago" value={payment.value} onChange={onPaymentChange} valid={payment.valid} errorMessage={payment.errorMessage} />
                        </div>
                    </div>

                </div>

            </div>

            <div className="mt-3">
                <button type="submit" onClick={() => onSubmit()} className="btn btn-primary float-right ml-1 ml-md-3  mb-2" disabled={(!date.valid || !description.valid || !price.valid || !payment.valid)}><i className="fas fa-check"/> Guardar</button>
                <button type="submit" onClick={() => navigate(`/paciente/${clientId}`)} className="btn btn-secondary float-right" ><i className="fas fa-times"/> Cancelar</button>
            </div>
            <SimpleModal ref={modal} title="Borrar Historia" body="Esta seguro que desea borrar la historia?" onConfirm={onModalConfirm} />
        </BasicWrapper>
    );
}

