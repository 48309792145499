import {createStore} from 'redux';

const reducer = (state,action) => {

    if (action.type === "SET_MYPROFILE"){
        return{
            ...state,
            myprofile: action.myprofile
        }
    }

    return state;
};

export default createStore(reducer,{
    myprofile : undefined
});