import React, {Component} from 'react';
import Collapse from 'react-bootstrap/Collapse'

class CollapseableItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: props.expanded ?? false
        };
    }

    render() {
        return (
            <li>
                <a
                    data-toggle="collapse"
                    className="dropdown-toggle"
                    onClick={() => this.setState({expanded: !this.state.expanded})}
                    aria-controls="collapseable"
                    aria-expanded={this.state.expanded}
                >
                    <i className={this.props.icon}/>{this.props.label}
                </a>
                <Collapse in={this.state.expanded}>
                    <ul className="list-unstyled" id="collapseable">
                        {this.props.children}
                    </ul>
                </Collapse>
            </li>
        );
    }
}

export default CollapseableItem;