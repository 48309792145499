import React, {useEffect, useRef, useState} from "react";
import './teeth.css'

export default function Teeth({value, onChange}) {

    const [selected, setSelected] = useState({});

    const onToothClick = toothNumber => {
        const newState = (selected[toothNumber] === true) ? {
            ...selected,
            [toothNumber]: false
        } : {
            ...selected,
            [toothNumber]: true
        }
        setSelected(newState)
        const keyArray = Object.keys(newState)
        const selectedArray = keyArray.filter(key => newState[key] === true)
        onChange(selectedArray.join())
    }

    const componentDidMount = () => {
        if (value) {
            const valueArray = value.split(',')
            const newState = valueArray.reduce((array, value) => ({ ...array, [value]: true}), {})
            setSelected(newState)
        }
    }
    useEffect(componentDidMount, [value]);

    return (
        <>
            <svg id="svg2"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 2826.99 4535.72">
                <g id="tooth32" onClick={() => onToothClick(32)}>
                    <path onClick={() => onToothClick(32)} id="outter32" fill={selected[32] === true ? "green" : "white" }
                          className="outter"
                          d="M170.92,1802c-20.16-27.63-17.52-67.84-3.93-114.1,51.88-118.83,145.09-144.44,290.28-52.76,45.55,18.38,81.65,53.74,105.4,89,23.37,34.7,45.06,51.4,33.51,112.76-26.66,103.37-72.72,184.45-203.26,168.49-51.86-6.31-97.66,7.27-163.26-44.09-25.4-42-74.33-39.24-58.74-159.31Z"
                          transform="translate(-100 -100)"/>
                    <path id="path5173"
                          className="cls-2"
                          d="M230.48,1648.61c54.62-118.08,144-24.2,230.84,54.13,17.77,21.35,40.33,34.77,47.7,73.36,26.76,68.05-39.61,106.81-70.83,153.34s-63.45,69.73-119.85,51.8c-131.91-48.62-136.95-112.38-116.43-179.18-41.66-98.88-13.44-104.08-2.47-132.4,10.34-5.71,20.67-8.41,31-21.05Z"
                          transform="translate(-100 -100)"/>
                    <path id="path5175"
                          className="cls-1"
                          d="M440.19,1704.09c-13.26,7.21-22.83,7.07-32.41,7-25.59-3.8-36.34,9.8-39.52,32.29-.34,14.14,3.43,29.66-18.41,36.57-18.82,17.16-9.43,25-7.13,35.19-.51,16-1,26.71-1.5,35.21-.44,57.37,15.28,56.14,25.15,76.12,5.95,6.48,13.3,12.49,8.39,22.56l11.24,9.89"
                          transform="translate(-100 -100)"/>
                    <path id="path5177"
                          className="cls-1"
                          d="M353,1636.26c5.38,11.75,10.6,23.5,19.63,35.26,10,7.5,14.26,23.63,16.79,42.3"
                          transform="translate(-100 -100)"/>
                    <path id="path5179"
                          className="cls-1"
                          d="M253,1659.94c19,.81,38,1.58,42.21,17l74.51,55.12"
                          transform="translate(-100 -100)"/>
                    <path id="path5181"
                          className="cls-1"
                          d="M217.44,1788l54.91,7.19c20.65,8.91,42.29,14.9,70.41,1.59"
                          transform="translate(-100 -100)"/>
                    <path id="path5183"
                          className="cls-1"
                          d="M325.44,1960.13l14.11-12.64c-3.91-36.14,3.26-41.56,14.18-36.58"
                          transform="translate(-100 -100)"/>
                    <path id="path5185"
                          className="cls-1"
                          d="M342.7,1820.74l29.59-2.74c13.12,9,26.25,14.43,39.38,19.82"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth31" onClick={() => onToothClick(31)}>
                    <path id="outter31" fill={selected[31] === true ? "green" : "white" }
                          className="outter"
                          d="M306.23,1224.86c99-85.94,172-36.3,245,13.32,19.72,24.47,39,48.93,66,73.41,82.68,79.47,67.13,133.4,19.25,178.93-29.85,33.11-58.43,65.59-73.48,91.36-17.25,17.76-33.11,31.38-46.58,37.9-63.74,28.62-88.08-11.3-123.86-31.31-128.56-67.76-174.6-162.14-141.52-282.06,3.64-46.23,25.39-69.07,55.14-81.55Z"
                          transform="translate(-100 -100)"/>
                    <path id="path5189"
                          className="cls-2"
                          d="M321.45,1329.13c-20.8-96.42,29.4-85.75,56.63-109.71,47.31-11.38,86.94-39.08,166.15,17.33,59,82.09,41.36,128.18,48.81,186,3.64,55.25-12.95,88.84-35.48,102.72-5.61,29.81-4.28,58.33-53.74,84.37-42.09,37.53-71.67,5.25-102.77-18.58-46.83-12.59-54.35-43.35-53.3-86-95.29-41.45-42-119.71-26.3-176.13Z"
                          transform="translate(-100 -100)"/>
                    <path id="path5191"
                          className="cls-1"
                          d="M506.2,1239.47c-9.69,11.38-22.62,12.94-25.47,45,4.38,32.87-13.68,65.69-31.25,98.51-18.16,17.67-12.27,65.59-17.16,100,17.8,17,35.64,17.15,53.48,15.63l33.72,29.67"
                          transform="translate(-100 -100)"/>
                    <path id="path5193"
                          className="cls-1"
                          d="M339.73,1341.85c12.07,13.09,21.09,28.2,54.86,26.91,19.26-2.47,38.51-3.71,57.72,8.6"
                          transform="translate(-100 -100)"/>
                    <path id="path5195"
                          className="cls-1"
                          d="M552.38,1353.68c-36.9,6.34-91.45,3.81-101.47,23.68"
                          transform="translate(-100 -100)"/>
                    <path id="path5197"
                          className="cls-1"
                          d="M360.45,1498.25c37.57,17,53.49-.85,73.27-12.49"
                          transform="translate(-100 -100)"/>
                    <path id="path5199"
                          className="cls-1"
                          d="M391.3,1550.44l17-52.07"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth30" onClick={() => onToothClick(30)}>
                    <path id="outter30" fill={selected[30] === true ? "green" : "white" }
                          className="outter"
                          d="M583.2,655c-81.59,28.42-87,67.62-87.35,107.53C491.7,938,640.71,935.07,669.57,921.58c45.4,5.62,76.94.4,101.75-10.08,46.34-3.56,68.65-31.25,76.06-73.94,6.78-22.92,9.09-48.08-2.38-80.18-8.38-17.93-2.68-35.12-54.16-55.31-54.29-6-71.47-19.76-93.88-34.73C664.73,632.36,624.64,640,583.2,655Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3878"
                          className="cls-1"
                          d="M660.79,655.17c.36,8.63,6.63,17.26-14.73,25.83-37,30-34.47,52.18-26,73.2,17.12,32.53,11,47.94-6.18,55.16-53.33,51.73-41.18,62.34-55.41,89.51"
                          transform="translate(-100 -100)"/>
                    <path id="path3880"
                          className="cls-1"
                          d="M594.7,872.24c49.84-7.06,90-26.77,101.08-84.22,5.88-33.26-13.37-52.24-39.48-67.34"
                          transform="translate(-100 -100)"/>
                    <path id="path3882"
                          className="cls-1"
                          d="M775.23,737.37c-65.14,6.53-86.09,23.25-77.7,43.76-.18,54.13,17,41.28,29.15,58.7"
                          transform="translate(-100 -100)"/>
                    <path id="path3884"
                          className="cls-1"
                          d="M667.11,875.88c9.24-12,6.14-24.12,1.81-36.2"
                          transform="translate(-100 -100)"/>
                    <path id="path3886"
                          className="cls-1"
                          d="M721,721.71c-22.74,20.91-25,31.66-25.11,41.31"
                          transform="translate(-100 -100)"/>
                    <path id="path3888"
                          className="cls-1"
                          d="M796,716.73c16,35.63.07,64.81,3.21,90.53,3,35.86-7.66,66.38-45.91,86.09-23.52,13.24-52.07,15.95-77.64,17.9"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth29" onClick={() => onToothClick(29)}>
                    <path id="outter29" fill={selected[29] === true ? "green" : "white" }
                          className="outter"
                          d="M504.19,928.2c-97.75,13.94-144.12,64.37-114.52,168.71,31.17,38.3,59.51,79.41,112.42,96.07,18.75-.39,35.21,1.5,46.46,8.57,16.23,4.44,34.83,4.41,54.93,1.56,37.84-20.1,88.72-3.38,110-70.14,17.59-53.41,14.59-100.45-27.82-135.28-83.81-70.1-129.88-64.2-181.51-69.49Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3065"
                          className="cls-1"
                          d="M528.12,935.3c-12.39,14.38-23.91,27.9-19.29,43.38-5.39,17.92-5.38,23.19-6.25,29.8-7.66,14.23-8.24,17.89-8.51,21.1.26,24.69,4.41,30.17,8.35,36.64,11.16,32.68.95,39.57-5.76,50.69s-16.3,5.57-19.82,36.57"
                          transform="translate(-100 -100)"/>
                    <path id="path3067"
                          className="cls-1"
                          d="M600.77,1159.44c-47.48-16.44-50.49-41.66-42.07-69.13-5-44.2,13-69.9,25.61-99.93"
                          transform="translate(-100 -100)"/>
                    <path id="path3069"
                          className="cls-1"
                          d="M627.92,1010.21c-19.72,2.39-39.44,3-59.18,12.52"
                          transform="translate(-100 -100)"/>
                    <path id="path3071"
                          className="cls-1"
                          d="M536.45,981.8c21.52,18.48,28.19,33.2,35.08,48"
                          transform="translate(-100 -100)"/>
                    <path id="path3073"
                          className="cls-1"
                          d="M505,1155c9.08-9.26,25.77-23,53.63-43.52"
                          transform="translate(-100 -100)"/>
                    <path id="path3890"
                          className="cls-1"
                          d="M580.07,1192c37.32-25.38,87.16-4.13,103.66-80.76,2.92-24.44,19.64-53.28-18.68-109.53"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth28" onClick={() => onToothClick(28)}>
                    <path id="outter28"  fill={selected[28] === true ? "green" : "white" }
                          className="outter"
                          d="M729.73,663.11c-54-18-79.35-51-85.1-94.19-6.67-42.21.7-83.38,26.19-123.2,22.29-62,44.4-58.59,66.54-62.76,19.75,2.49,37.7,1.36,55.18-.72,52.83-2.34,72.51,21.62,85.24,37.29,44.59,59.7,39.66,94.49,50.51,137.21,5.57,47.16,3.11,87.5-21,109.42-73.31,54.83-85.77,28-116.45,25.56C733.73,667.66,748.43,672,729.73,663.11Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3894"
                          className="cls-1"
                          d="M696.27,601.82c2.57-58.39,7.48-113.78,34.85-140.43,27.09-28.38,61.94-48.95,109.63-56.61"
                          transform="translate(-100 -100)"/>
                    <path id="path3896"
                          className="cls-1"
                          d="M863,456.56C895.36,582.32,909.37,612,824.9,534.05"
                          transform="translate(-100 -100)"/>
                    <path id="path3898"
                          className="cls-1"
                          d="M787.71,577.92c71.17,86.13,24,73.39-52.68,36.07"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth27" onClick={() => onToothClick(27)}>
                    <path id="outter27" fill={selected[27] === true ? "green" : "white" }
                          className="outter"
                          d="M897.9,308.38c133.39-87.56,106.06-62,163.15-81.47,19.9-7.11,36.41-8.18,44.81,5.29l31,31.11c21,26.44,19.64,32.05,22.31,41.44,9.91,81.75-1.54,80.72-3.75,115.51-15.25,29.61-33.68,56-64.84,69.66-19.23,13.57-45.87,12.23-74.16,7.56l-46.52-11.33c-18.24,2.75-34-9.42-50-19.95-33.75-23.69-42.47-57.32-54.07-89.8-2.34-21,3.64-39.62,17.39-56Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3902"
                          className="cls-1"
                          d="M935.44,456.75c-17.9-27.84-46.56-50.33-11.79-104.34,31.89-24.88,56.85-32.56,84.61-47.19,23.89-12,47.6-23.7,64.71-22.24,71.25-6.84,41.91,10.18,61.17,15.67"
                          transform="translate(-100 -100)"/>
                    <path id="path3904"
                          className="cls-1"
                          d="M1084.91,331.28c6.1,31.91,11.78,64.64,29.12,74.22"
                          transform="translate(-100 -100)"/>
                    <path id="path3906"
                          className="cls-1"
                          d="M1044.05,463.93c2.52-27.17-37.09-38.66-69.69-52.76"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth26" onClick={() => onToothClick(26)}>
                    <path id="outter26" fill={selected[26] === true ? "green" : "white" }
                          className="outter"
                          d="M1158.39,254.75c.65,6,35.73,67.87,44.57,95.81,12,30.16,31,57.43,81.71,71.76,41.41-5.08,83.4-6.17,118.25-55.72l85.69-130,15.62-41.34c11.29-29.85-13.15-57.29-36-69.06l-62-16.54c-36.22-2.32-60.27-12.94-131,8.28l-66.42,14.48c-24.16,10.65-56.43,6.48-57.06,60.19,1.27,20.53-6.77,39.49,6.74,62.09Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3910"
                          className="cls-1"
                          d="M1222,334.23l-15.18-126.77c8.23,0-9.27-25.59,21.65-37,112.89-44,178.14-11.74,196.55-2.07s34.4,36.1,30.87,63.87-11.26,34.45-16.5,48.24"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth25" onClick={() => onToothClick(25)}>
                    <path id="outter25" fill={selected[25] === true ? "green" : "white" }
                          className="outter"
                          d="M174.19,2778c-5.35-37.44-29.28-41-10-123.3,10-58.7,38.81-107.19,103.8-135.93,39.35-5.6,75.21-33.66,120.69.31,13.26,11.59,23.14,17.77,31.84,22.5,18.08,18.82,53.23,19.94,70.45,91.56,0,27.3-6.67,37.87,5.78,96.57,3.95,22.62,15.44,36.81,2.38,78.46-14.06,19.26-12.79,39.66-56.18,56.75-11.91,11-106.51,21.37-137.12,20.33s-29.09,9.4-75.77-34.68c-18.52-23.38-35.33-32.86-55.85-72.57Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3914"
                          className="cls-2"
                          d="M391.17,2548.44c-35-52.16-74-29-113.81,7.46-22.6,27.15-29.59,55.76-27,85.28,1,17.72,8.69,29.7,25.78,33.68-11.28,6.31-15.79,18.06-15.61,33.58l15.28,89.7c14.57,37,26.15,36.76,38.67,48.38,6.68,3.38,13.45,6.68,22.39,7.81,12.37-4.17,24.73-6.72,37.18-42.14-10.52-3.38,33.2-20.19,1-53.45-10.37-11.34,24.68-48.09,4.51-78.43-10.32-33.39-20.15-62.24-28.25-75.08,21.79-3.54,15.29-23.36,22.51-35.28"
                          transform="translate(-100 -100)"/>
                    <path id="path3916"
                          className="cls-2"
                          d="M325.53,2598.27l25.84,7"
                          transform="translate(-100 -100)"/>
                    <path id="path3920"
                          className="cls-2"
                          d="M427.05,2673.53c-13.69,6.17-25.55,16.67-45.71,7.64"
                          transform="translate(-100 -100)"/>
                    <path id="path3922"
                          className="cls-2"
                          d="M343.14,2783.66c16.11-2.48,28.16-10.66,32.84-29.22"
                          transform="translate(-100 -100)"/>
                    <path id="path3924"
                          className="cls-2"
                          d="M313.26,2674.1c21.38-4.13,44.93-16.8,62.08-4.15"
                          transform="translate(-100 -100)"/>
                    <path id="path3926"
                          className="cls-2"
                          d="M380.92,2843.24c13.62-.42,26.49.73,42.25-4.2,22.52-25.16,30.6-25.19,44.92-36.09,2.7-.81,31.08-30.37,10.54-75-11.34-22.82-14.09-30.71-9.35-47.44-11.14-39-5.1-47.8-7.57-71.57"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth24" onClick={() => onToothClick(24)}>
                    <path id="outter24" fill={selected[24] === true ? "green" : "white" }
                          className="outter"
                          d="M294.59,2890.43c-32.14,14.94-64.95,24.43-95,56.65-24.86,19.58-43.36,54.83-47.74,124.87-2.09,49.25-14,97.24,18.57,150.91,28,23.59,10.84,31.62,86.88,71.78,39.18,16.41,67.3,52.6,127.51,31.37,29.12-8.25,39-5,88.86-25.63,21.14-4.81,38.65-16.94,48.39-44.7,10.49-18,19.26-42.9,25.21-79.24-2-31.4-6.78-44.32-11-60.38-6.84-42.21-4.27-59.8.19-72.41.46-21.26-3.78-42.54-17.07-63.84-7-23-11.59-44.78-52.41-70-37.17-27.95-61.29-49-172.36-19.42Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3930"
                          className="cls-2"
                          d="M365.08,3296.65c14.52-6.93,14.69-19,58.66-15.36,10.63-1.38,21.27-1.58,31.94-16.3L479,3250.4l19.87-17.19c15.51-16.86,19.11-35.07,13.94-54.28-2.72-21.31-7.08-47-3.32-50.87,2-13.21-2-34.63-6.74-56.91,2.16-28.76,22.56-24.75-4-105.19-1.73-17.14-10.79-25.91-27.52-25.93-13.61-2.68-17.26-9-34.42-22.5-12.19-7.22-18.5-14.42-45.63-21.68-20.7,2.63-41.37-4.25-62.11,12.77l-36.29,32.67c-29.26,24.25-32.39,37.7-34.61,50.77-8.14,19.52-5,39.07-1.88,58.62.69,16.66,6.42,30.45,23.17,38-8,9.58-17.07,14.19-20.81,45.63-8.58,54.61,4.62,68.61,13.56,90.56,17.46,25.37,34.94,44.48,52.42,65.65"
                          transform="translate(-100 -100)"/>
                    <path id="path3932"
                          className="cls-2"
                          d="M413.39,2952.81c-15.72,10.72-28,27.41-34.62,54.22,2.82,21,7.68,37.9,12.78,54.34,4.3,22.68,16.16,21.4,10.15,76.75-10.77,10.45-13.43,18.22-10.41,24.12,19.47,41.49,12.68,78.13-18.39,110.29"
                          transform="translate(-100 -100)"/>
                    <path id="path3934"
                          className="cls-2"
                          d="M442.86,3222.72c-9.57-20.79-20.48-40.92-48.14-52.72-3.82-11.77-16.81-16.44-41.35-12.17"
                          transform="translate(-100 -100)"/>
                    <path id="path3936"
                          className="cls-2"
                          d="M313.91,3084.45c39.14-3.26,73.53-3.76,85.32,8.84"
                          transform="translate(-100 -100)"/>
                    <path id="path3938"
                          className="cls-2"
                          d="M497.47,3108.2c-30.63-8.5-51.78-25.25-99.09-19.22"
                          transform="translate(-100 -100)"/>
                    <path id="path3940"
                          className="cls-2"
                          d="M486.36,3070.24l-42.3,22.31"
                          transform="translate(-100 -100)"/>
                    <path id="path3942"
                          className="cls-2"
                          d="M317.6,2989.63c28.09,5.53,47.72,11,62,16.54"
                          transform="translate(-100 -100)"/>
                    <path id="path3944"
                          className="cls-2"
                          d="M354.81,2938.86c11.14,21.87,25.09,43.75,23.1,65.58"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth23" onClick={() => onToothClick(23)}>
                    <path id="outter23" fill={selected[23] === true ? "green" : "white" }
                          className="outter"
                          d="M500.36,3321.14c20.13-6.26,42.26,5.89,64.6,20L595,3375.7c21.2,20.17,30.08,40.31,28.29,60.42-1.92,35.05,7.41,70.13,30.76,105.25,14.94,43.1,24.7,84.73-1.16,116.38-15.8,28.73-35,56.09-73.48,75.67-40.24,16.6-75.72,39.19-124.26,45.36-31.72,2.46-57.48,12.9-108.59-10.63l-69.7-46.73c-11.74-8.59-22.92-13.4-41.21-66.49-6-49.32-29-96.57-1.33-150l44.23-101.61c20.91-26.12,34.8-54.81,79.5-72.21,26.05-8.4,43.55-21.51,93.15-17Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3948"
                          className="cls-2"
                          d="M570.92,3369.6c10.63,22.16,27.19,44.33,27.41,66.45,5.37,44.66,19.83,75.76,34.21,107,8.4,21.77,21.43,37.92,10.12,83.65-8,28.68-15.49,57.62-57.08,70.54L514,3728.94c-20.41,9.6-48.11,8.2-77.6,4.1-28.71-10.31-57.45-9.87-86.08-50.22-6.16-12.06-9.78-29.17-6.74-59.5"
                          transform="translate(-100 -100)"/>
                    <path id="path3950"
                          className="cls-2"
                          d="M329.8,3600.87c-13.81.16-24.54-4.51-22.34-29.37,3.2-47.21-3.28-95.42,21.06-140.46,0-26.23-1.67-53.51,18.28-66.33,26.4-19.27,39-52.37,94.93-42,27.6,6.12,45.94,6.43,55.17,1"
                          transform="translate(-100 -100)"/>
                    <path id="path3952"
                          className="cls-2"
                          d="M495.05,3374.58c-10.26,25.89-16.56,50.28-41.6,81.78,10.19,33.72,24.76,45.8,39.51,57,3.67,9.52,9.63,4.87,6.75,55.19,3.61,48.29,35.08,64.91,53.2,96.7"
                          transform="translate(-100 -100)"/>
                    <path id="path3954"
                          className="cls-2"
                          d="M389.31,3589.82c36.25-17.66,72.52-41.07,108.71-32.48"
                          transform="translate(-100 -100)"/>
                    <path id="path3956"
                          className="cls-2"
                          d="M588.71,3489.48c-26.62,41.05-59,38.3-89.78,47.18"
                          transform="translate(-100 -100)"/>
                    <path id="path3958"
                          className="cls-2"
                          d="M535.41,3433.3c-21.4,24.12-42.62,48.29-41.59,79.2"
                          transform="translate(-100 -100)"/>
                    <path id="path3960"
                          className="cls-2"
                          d="M347.53,3414.71c24.59,29.08,58.27,45.52,105.92,42.52"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth22" onClick={() => onToothClick(22)}>
                    <path id="outter22" fill={selected[22] === true ? "green" : "white" }
                          className="outter"
                          d="M653.51,3765.51c84.63,42.06,83.39,64.68,86,96.78-6.41,38.44-4.7,52.74-4.5,71.54,1.15,39.51-23.47,49.9-39,70.59-49.73,49.76-76.22,33.67-111.31,41.95A621.09,621.09,0,0,1,514,4031.53c-52.63-12.78-48-32.55-68-49.32-25.58-34.33-27.83-78.19-14.32-128.49,11.08-29.92,33.56-52.3,57.09-74l57.84-29.16c44.4-18.35,95.26,6.85,106.86,14.94Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3964"
                          className="cls-2"
                          d="M574.2,3762.72c-18.43,3.7-40.75,6.45-48.4,47.28.16,17.76-5.54,31.6-12.18,44.8-8,42.58,12.93,70.77,31.63,100.08,14.52,24.51,32.49,27,49.91,32.89,19,10.05,33.27,3.31,43.16-18.85,6-12.65,7.3-31.33,21.64-33.56,6.47-11.43,7.33-21,7-30.16-8.15-15.31-1.54-36.47,1-56-33.46-1.68-41.68-28.47-45.53-59.6-15.57-5.9-30.51-8-48.21-26.85Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3966"
                          className="cls-2"
                          d="M614.23,3950.75c19.84-18,19.48-49.5,19.17-81-12.19-52.8-34.31-78-69.65-66.56"
                          transform="translate(-100 -100)"/>
                    <path id="path3968"
                          className="cls-2"
                          d="M652.25,3917.23c-1.56-27.08-9.81-39.22-20.57-45.74"
                          transform="translate(-100 -100)"/>
                    <path id="path3970"
                          className="cls-2"
                          d="M602.58,3787.79l2.53,24.15"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth21" onClick={() => onToothClick(21)}>
                    <path id="outter21" fill={selected[21] === true ? "green" : "white" }
                          className="outter"
                          d="M638.93,4064.61c-33.56,46.28-41.61,79.47-38.21,106.8,10,47.54,15.23,98.56,65.21,117.41,79.75,26.57,96.92,4.52,129.32-5.7q55.62-27,87.25-67.87c16.16-11.17,15-22.38,15.61-33.58-2.5-20.82,2-34.87,3.58-51.72,2.44-32.67-11.66-55.47-19.61-81.94-23.52-19.15-40.75-45.45-79.19-47.63l-66.37-1.89C695.63,3996.34,667.74,4031.81,638.93,4064.61Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3872"
                          className="cls-2"
                          d="M656.15,4074.14c11.85,16.67,26.57,36.31,34.36,46.64,4.18,7.38.65,12.58,1.66,24.14-5,30,4.32,28.08,12.84,34.52,8.69,6.74,17.61,13,24.07,24.2,7.42,22.42,17.95,17.43,27.53,20.76l39.64,7c31.45,9.47,36.39-5,53.47-5.89"
                          transform="translate(-100 -100)"/>
                    <path id="path3874"
                          className="cls-2"
                          d="M840.36,4175.48c2.07,11.45-5.36,24.23,11.12,33.65"
                          transform="translate(-100 -100)"/>
                    <path id="path3877"
                          className="cls-2"
                          d="M755,4188.19l73.5-87.74c15.45-18.66,27.26-23.18,28.41,13.87"
                          transform="translate(-100 -100)"/>
                    <path id="path3879"
                          className="cls-2"
                          d="M743.12,4109.71c7.2-22.11,27.46-44.18,62.24-66.22-13.47,4.71-26.9,9.51-44.83.75-21.24-10.41-31.36,1.56-44,8.51"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth20" onClick={() => onToothClick(20)}>
                    <path id="outter20" fill={selected[20] === true ? "green" : "white" }
                          className="outter"
                          d="M920.41,4225.69c57.68-8.1,108.17-8.37,118,35.66l29.08,86.28c20.91,65.76-1,52.45-4.5,73.26l-43.24,50.75c-16.52,16.73-34.32,32.55-73.36,33.43-57.24-2.11-115.15-3-127.36-85.68l-4.92-96.56c11.87-46.14,24.86-91.63,106.29-97.14Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3883"
                          className="cls-2"
                          d="M849.51,4303.09c17,53.74,27.59,115.36,79.85,125.21,40.64,18.54,82.19,48.38,116.41-12.62"
                          transform="translate(-100 -100)"/>
                    <path id="path3885"
                          className="cls-2"
                          d="M1026.88,4386.32c-20.66,12.28-42.14,26.69-44.81-7.88-3.74-36,11.83-52.64,18.31-78.4"
                          transform="translate(-100 -100)"/>
                    <path id="path3887"
                          className="cls-2"
                          d="M885.72,4302.33c4.83-21.5,20.66-37.9,55.29-45.55"
                          transform="translate(-100 -100)"/>
                    <path id="path3889"
                          className="cls-2"
                          d="M903.7,4350.65c11.75,11.21,23.56,2.09,35.43-32.67,6.17-26.42,17.12-33.55,27.7-42.17"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth19" onClick={() => onToothClick(19)}>
                    <path id="path3891"
                          className="cls-2"
                          d="M1138.09,4384.88c-22.07,32.13-83.28,64.15-14,96.52"
                          transform="translate(-100 -100)"/>
                    <path id="path3893"
                          className="cls-2"
                          d="M1189.73,4418.64l15.32,74.18c10,35.71,20.06,21,30.17.08"
                          transform="translate(-100 -100)"/>
                    <path id="path3895"
                          className="cls-2"
                          d="M1059.48,4446.75c1.58,26.18-.86,54.93,31.74,61.29,27.72,9.55,62.5,19.13,73.2,28.64,45.89,25.34,50.17,12.07,68.93,12.25"
                          transform="translate(-100 -100)"/>
                    <path id="outter19" fill={selected[19] === true ? "green" : "white" }
                          className="outter"
                          d="M1227.8,4363.57c-16.38-45.75-56.38-46.28-83.55-24.36l-38.93,49-45.8,43.84c-6.66,20.52-24.58,19.26-3.69,93.1,12.56,39.94,37.82,57.78,68.79,65.69,39.72,11.41,73.73,4.08,104.35-14.38,20.87-21.5,53.91-31.65,45-80.91-8.85-22.16-9.27-27.34-28.27-69.91-6-20.61-12-30.77-17.94-62.11Z"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth18" onClick={() => onToothClick(18)}>
                    <path id="outter18" fill={selected[18] === true ? "green" : "white" }
                          className="outter"
                          d="M1327.62,4432.79c-13,28.55-22.19,60.13-45.9,80.06-10.63,10.65-19.79,24.29-8,80.15,12.26,23.27,33.27,36.89,66.28,37.24,26.62-5,49.54-12.14,91.4-8.38,20.15-4.87,38.53,1.66,63-30.87,8.09-15.36,20.68-25.15.2-75.86l-62.71-83.79c-17-12.67-18.88-32.18-58.53-34.63-31,.94-36.83,19.62-45.79,36.08Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3901"
                          className="cls-2"
                          d="M1286.71,4585.27c17.11,2.9,33.31,8.46,52.58,2.73l31,2.67c21.84,3.23,40.62.29,60.35-.71,29.92-1.56,60.74-2.7,52.64-22.27"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth17" onClick={() => onToothClick(17)}>
                    <path id="outter17" fill={selected[17] === true ? "green" : "white" }
                          className="outter"
                          d="M324.38,2003.44c57.49,5.25,109.33,18.93,144.24,50.37,49.84,25.76,81.64,52.76,79,82.14,16,44.76,3.54,77.28-5.81,111.13l-26.55,55.57c-16.8,32.33-51.51,53.86-105.8,63.57-63.92,10.59-127.84,23.3-191.64-13.06-72.63-27.1-76.08-65.53-83.06-103.39-21.43-38.83-35.79-85.6-27.29-157.93,7.54-33.07,17.11-75.11,64-94.32s88.91-9.25,152.92,5.92Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3772"
                          className="cls-2"
                          d="M153.34,2062.49c-53.37,75.74-16.81,109.11,5.24,149.31-6,68.17-4.21,120.57,41.95,114.2,68.06,48.49,117.69,22.46,171.8,14.53,7.76-12.31,8.25-28.28,29.66-33.72,0,0,47.66-102.22,53.83-118.17s11.16-45.51-20.92-77.52c0,0-49.63-51.86-81.52-66.41-15.16-6.92-33-4.83-49.27-8.58-33.3-7.68-98.52-28.43-98.52-28.43-31.24-6.9-50.68,9.28-52.25,54.79Z"
                          transform="translate(-100 -100)"/>
                    <path id="path3774"
                          className="cls-1"
                          d="M372.52,2268.7c2-1.35-33.88-24.23-59.07-33.95-12.88-5-20.58-20.46-23.86-33.87-6.73-27.61,20-62.64,11.49-84.48s-26.41-22.82-42.17-29.69c-10.56-4.59-16.82-.76-33.79-7.13"
                          transform="translate(-100 -100)"/>
                    <path id="path3776"
                          className="cls-4"
                          d="M351.85,2091.18c-22.45,6.61-51,12.2-52.17,22.4"
                          transform="translate(-100 -100)"/>
                    <path id="path3778"
                          className="cls-1"
                          d="M227.6,2209.17c9.08-4.53,20.76-10,35.23-5.54s22,5.83,26.78-7"
                          transform="translate(-100 -100)"/>
                    <path id="path3780"
                          className="cls-1"
                          d="M350.16,2201c-21.15-.87-44.26,11.82-61.94-12.84"
                          transform="translate(-100 -100)"/>
                    <path id="path4179"
                          className="cls-1"
                          d="M302.18,2073.88c5.1,12.61,7.81,24.73-1.16,34.48"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth16" onClick={() => onToothClick(16)}>
                    <path id="outter16" fill={selected[16] === true ? "green" : "white" }
                          className="outter"
                          d="M2856.07,1802.45c20.16-27.63,17.52-67.84,3.92-114.1-51.88-118.83-145.08-144.44-290.28-52.76-45.54,18.39-81.65,53.74-105.4,89-23.36,34.7-45,51.4-33.51,112.76,26.67,103.37,72.73,184.46,203.26,168.49,51.87-6.31,97.67,7.27,163.27-44.09C2822.72,1919.73,2871.65,1922.53,2856.07,1802.45Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4292"
                          className="cls-2"
                          d="M2796.51,1649.08c-54.63-118.08-144-24.2-230.84,54.13-17.77,21.35-40.34,34.77-47.7,73.36-26.77,68,39.61,106.81,70.82,153.34s63.45,69.74,119.86,51.8c131.9-48.62,136.94-112.38,116.43-179.18,41.66-98.87,13.44-104.08,2.47-132.4C2817.21,1664.42,2806.87,1661.72,2796.51,1649.08Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4294"
                          className="cls-1"
                          d="M2586.8,1704.56c13.26,7.21,22.83,7.07,32.41,7,25.59-3.8,36.34,9.8,39.52,32.29.34,14.14-3.44,29.66,18.41,36.57,18.82,17.17,9.43,25,7.13,35.19.51,16,1,26.71,1.5,35.21.43,57.37-15.28,56.14-25.15,76.12-5.95,6.48-13.3,12.5-8.39,22.56l-11.25,9.89"
                          transform="translate(-100 -100)"/>
                    <path id="path4296"
                          className="cls-1"
                          d="M2673.94,1636.73c-5.37,11.75-10.6,23.5-19.62,35.26-10,7.5-14.26,23.63-16.79,42.3"
                          transform="translate(-100 -100)"/>
                    <path id="path4298"
                          className="cls-1"
                          d="M2774,1660.41c-18.95.81-37.95,1.58-42.2,17l-74.51,55.13"
                          transform="translate(-100 -100)"/>
                    <path id="path4300"
                          className="cls-1"
                          d="M2809.55,1788.49l-54.91,7.18c-20.65,8.91-42.29,14.91-70.42,1.59"
                          transform="translate(-100 -100)"/>
                    <path id="path4302"
                          className="cls-1"
                          d="M2701.55,1960.6,2687.43,1948c3.91-36.14-3.26-41.56-14.18-36.58"
                          transform="translate(-100 -100)"/>
                    <path id="path4304"
                          className="cls-1"
                          d="M2684.28,1821.21l-29.58-2.74c-13.12,9-26.25,14.44-39.39,19.82"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth15" onClick={() => onToothClick(15)}>
                    <path id="outter15" fill={selected[15] === true ? "green" : "white" }
                          className="outter"
                          d="M2720.75,1225.34c-99-85.95-172-36.31-245,13.31-19.72,24.47-39,48.93-66,73.42-82.69,79.46-67.13,133.39-19.25,178.92,29.85,33.11,58.43,65.6,73.48,91.36,17.25,17.76,33.11,31.38,46.58,37.9,63.74,28.63,88.08-11.29,123.86-31.31,128.56-67.75,174.6-162.14,141.52-282.06-3.65-46.23-25.39-69.06-55.15-81.54Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4308"
                          className="cls-2"
                          d="M2705.53,1329.6c20.8-96.42-29.39-85.75-56.62-109.71-47.32-11.38-86.95-39.08-166.16,17.34-59,82.08-41.35,128.17-48.8,186-3.65,55.26,12.94,88.84,35.48,102.72,5.6,29.81,4.28,58.34,53.74,84.37,42.09,37.53,71.67,5.25,102.77-18.58,46.82-12.58,54.34-43.35,53.29-86,95.29-41.44,42-119.7,26.3-176.13Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4310"
                          className="cls-1"
                          d="M2520.79,1239.94c9.69,11.38,22.62,12.94,25.47,45-4.38,32.87,13.68,65.69,31.24,98.51,18.17,17.68,12.27,65.6,17.17,100-17.8,17-35.64,17.16-53.48,15.64l-33.73,29.66"
                          transform="translate(-100 -100)"/>
                    <path id="path4312"
                          className="cls-1"
                          d="M2687.26,1342.33c-12.08,13.08-21.09,28.2-54.86,26.9-19.26-2.46-38.51-3.71-57.73,8.6"
                          transform="translate(-100 -100)"/>
                    <path id="path4314"
                          className="cls-1"
                          d="M2474.61,1354.15c36.9,6.34,91.44,3.81,101.47,23.68"
                          transform="translate(-100 -100)"/>
                    <path id="path4316"
                          className="cls-1"
                          d="M2666.54,1498.72c-37.58,17-53.5-.85-73.27-12.49"
                          transform="translate(-100 -100)"/>
                    <path id="path4318"
                          className="cls-1"
                          d="M2635.69,1550.91l-17-52.07"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth14" onClick={() => onToothClick(14)}>
                    <path id="outter14" fill={selected[14] === true ? "green" : "white" }
                          className="outter"
                          d="M2443.79,655.44c81.59,28.42,86.95,67.62,87.35,107.53,4.15,175.55-144.87,172.57-173.73,159.08-45.4,5.62-76.93.4-101.75-10.08-46.33-3.55-68.65-31.25-76-73.94-6.78-22.91-9.1-48.08,2.37-80.18,8.39-17.93,2.69-35.11,54.17-55.31,54.29-6,71.47-19.75,93.87-34.73,32.23-35,72.32-27.34,113.77-12.37Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4334"
                          className="cls-1"
                          d="M2366.2,655.65c-.37,8.62-6.63,17.25,14.72,25.82,37,30,34.48,52.18,26.06,73.21-17.12,32.53-11,47.93,6.18,55.15,53.32,51.73,41.18,62.34,55.4,89.51"
                          transform="translate(-100 -100)"/>
                    <path id="path4336"
                          className="cls-1"
                          d="M2432.29,872.71c-49.84-7.06-90-26.77-101.09-84.21-5.88-33.27,13.38-52.25,39.48-67.35"
                          transform="translate(-100 -100)"/>
                    <path id="path4338"
                          className="cls-1"
                          d="M2251.76,737.84c65.13,6.53,86.08,23.25,77.7,43.76.18,54.13-17,41.28-29.16,58.7"
                          transform="translate(-100 -100)"/>
                    <path id="path4340"
                          className="cls-1"
                          d="M2359.88,876.35c-9.24-12-6.15-24.12-1.82-36.2"
                          transform="translate(-100 -100)"/>
                    <path id="path4342"
                          className="cls-1"
                          d="M2306,722.18c22.74,20.91,25,31.66,25.11,41.32"
                          transform="translate(-100 -100)"/>
                    <path id="path4344"
                          className="cls-1"
                          d="M2231,717.21c-16,35.62-.08,64.8-3.22,90.52-3,35.86,7.67,66.39,45.92,86.09,23.52,13.25,52.06,15.95,77.63,17.9"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth13" onClick={() => onToothClick(13)}>
                    <path id="outter13" fill={selected[13] === true ? "green" : "white" }
                          className="outter"
                          d="M2522.79,928.67c97.76,14,144.12,64.37,114.53,168.71-31.17,38.3-59.51,79.41-112.43,96.07-18.74-.38-35.21,1.5-46.45,8.58-16.24,4.44-34.83,4.41-54.93,1.55-37.84-20.1-88.73-3.38-110-70.14-17.6-53.41-14.59-100.45,27.81-135.28,83.82-70.09,129.89-64.2,181.51-69.49Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4322"
                          className="cls-1"
                          d="M2498.87,935.78c12.39,14.37,23.91,27.89,19.28,43.38,5.4,17.92,5.38,23.18,6.26,29.79,7.66,14.23,8.24,17.89,8.51,21.1-.26,24.69-4.41,30.17-8.36,36.64-11.16,32.68-.94,39.57,5.77,50.69s16.29,5.57,19.81,36.57"
                          transform="translate(-100 -100)"/>
                    <path id="path4324"
                          className="cls-1"
                          d="M2426.21,1159.91c47.48-16.44,50.5-41.66,42.08-69.12,5-44.21-13-69.91-25.62-99.94"
                          transform="translate(-100 -100)"/>
                    <path id="path4326"
                          className="cls-1"
                          d="M2399.06,1010.68c19.73,2.4,39.45,3,59.19,12.53"
                          transform="translate(-100 -100)"/>
                    <path id="path4328"
                          className="cls-1"
                          d="M2490.54,982.28c-21.53,18.47-28.19,33.2-35.09,48"
                          transform="translate(-100 -100)"/>
                    <path id="path4330"
                          className="cls-1"
                          d="M2522,1155.43c-9.08-9.25-25.77-23-53.64-43.52"
                          transform="translate(-100 -100)"/>
                    <path id="path4346"
                          className="cls-1"
                          d="M2446.92,1192.5c-37.32-25.38-87.16-4.12-103.66-80.76-2.92-24.43-19.65-53.27,18.68-109.53"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth12" onClick={() => onToothClick(12)}>
                    <path id="outter12" fill={selected[12] === true ? "green" : "white" }
                          className="outter"
                          d="M2297.26,663.58c54-18,79.35-51,85.09-94.18,6.68-42.21-.69-83.38-26.18-123.21-22.29-62-44.41-58.59-66.54-62.76-19.75,2.49-37.7,1.36-55.18-.72-52.83-2.34-72.51,21.62-85.25,37.3-44.58,59.69-39.66,94.48-50.5,137.2-5.58,47.17-3.12,87.5,21,109.43,73.3,54.82,85.76,28,116.44,25.55,57.13-24.06,42.44-19.73,61.14-28.61Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4350"
                          className="cls-1"
                          d="M2330.72,602.29c-2.57-58.39-7.48-113.78-34.86-140.43-27.08-28.37-61.93-48.94-109.63-56.61"
                          transform="translate(-100 -100)"/>
                    <path id="path4352"
                          className="cls-1"
                          d="M2164,457c-32.33,125.75-46.34,155.43,38.13,77.48"
                          transform="translate(-100 -100)"/>
                    <path id="path4354"
                          className="cls-1"
                          d="M2239.27,578.39c-71.17,86.13-24,73.39,52.68,36.07"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth11" onClick={() => onToothClick(11)}>
                    <path id="outter11" fill={selected[11] === true ? "green" : "white" }
                          className="outter"
                          d="M2129.08,308.85c-133.38-87.56-106-62-163.14-81.47-19.9-7.11-36.42-8.17-44.81,5.29l-31,31.12c-21,26.43-19.63,32-22.3,41.43-9.91,81.75,1.54,80.72,3.75,115.51,15.25,29.61,33.67,56,64.84,69.66,19.23,13.57,45.86,12.24,74.15,7.57l46.53-11.33c18.24,2.74,34-9.43,49.94-20,33.75-23.69,42.48-57.32,54.08-89.8,2.33-21-3.64-39.62-17.39-56Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4358"
                          className="cls-1"
                          d="M2091.54,457.23c17.91-27.84,46.56-50.34,11.8-104.35-31.89-24.88-56.85-32.56-84.61-47.19-23.89-12-47.6-23.69-64.71-22.24-71.25-6.84-41.92,10.18-61.17,15.67"
                          transform="translate(-100 -100)"/>
                    <path id="path4362"
                          className="cls-1"
                          d="M1982.94,464.41c-2.52-27.17,37.09-38.67,69.69-52.77"
                          transform="translate(-100 -100)"/>
                    <path id="path4360"
                          className="cls-1"
                          d="M1942.08,331.75c-6.1,31.91-11.79,64.64-29.12,74.22"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth10" onClick={() => onToothClick(10)}>
                    <path id="outter10" fill={selected[10] === true ? "green" : "white" }
                          className="outter"
                          d="M1868.6,255.22c-.65,6-35.73,67.87-44.58,95.81-12,30.17-31,57.44-81.71,71.77-41.4-5.09-83.39-6.18-118.25-55.73l-85.68-129.95-15.63-41.34c-11.28-29.85,13.15-57.29,36-69.06l62-16.54c36.23-2.32,60.28-12.94,131.06,8.28l66.42,14.48c24.16,10.65,56.43,6.48,57,60.2-1.27,20.53,6.77,39.48-6.73,62.08Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4366"
                          className="cls-1"
                          d="M1805,334.7l15.19-126.76c-8.24,0,9.27-25.6-21.65-37-112.9-44-178.14-11.74-196.56-2.07s-34.4,36.1-30.87,63.88,11.27,34.44,16.51,48.23"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth9" onClick={() => onToothClick(9)}>
                    <path id="outter9" fill={selected[9] === true ? "green" : "white" }
                          className="outter"
                          d="M2852.79,2778.52c5.35-37.45,29.29-41,10-123.31-10-58.7-38.81-107.19-103.81-135.93-39.34-5.6-75.2-33.65-120.69.31-13.26,11.59-23.13,17.77-31.84,22.5-18.08,18.83-53.22,19.94-70.45,91.57,0,27.29,6.68,37.86-5.78,96.56-3.94,22.63-15.43,36.81-2.38,78.46,14.07,19.26,12.8,39.66,56.19,56.75,11.9,11,106.51,21.37,137.12,20.33s29.08,9.41,75.77-34.68c18.51-23.38,35.33-32.86,55.84-72.56Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4370"
                          className="cls-2"
                          d="M2635.81,2548.91c35-52.16,74.05-29,113.82,7.46,22.6,27.15,29.59,55.76,26.94,85.28-1,17.72-8.68,29.7-25.77,33.69,11.28,6.3,15.79,18,15.6,33.58l-15.28,89.69c-14.57,37-26.14,36.77-38.66,48.38-6.69,3.38-13.46,6.68-22.4,7.82-12.36-4.18-24.73-6.73-37.18-42.15,10.53-3.37-33.19-20.19-1-53.44,10.37-11.34-24.69-48.1-4.51-78.44,10.32-33.39,20.15-62.23,28.25-75.08-21.8-3.54-15.29-23.36-22.51-35.28"
                          transform="translate(-100 -100)"/>
                    <path id="path4372"
                          className="cls-2"
                          d="M2701.46,2598.74l-25.84,7"
                          transform="translate(-100 -100)"/>
                    <path id="path4374"
                          className="cls-2"
                          d="M2599.93,2674c13.7,6.16,25.55,16.66,45.71,7.63"
                          transform="translate(-100 -100)"/>
                    <path id="path4376"
                          className="cls-2"
                          d="M2683.84,2784.13c-16.1-2.47-28.16-10.66-32.83-29.22"
                          transform="translate(-100 -100)"/>
                    <path id="path4378"
                          className="cls-2"
                          d="M2713.73,2674.57c-21.39-4.13-44.94-16.8-62.08-4.15"
                          transform="translate(-100 -100)"/>
                    <path id="path4380"
                          className="cls-2"
                          d="M2646.07,2843.71c-13.62-.42-26.5.73-42.25-4.2-22.53-25.15-30.6-25.19-44.93-36.09-2.69-.81-31.08-30.37-10.54-75,11.34-22.82,14.09-30.7,9.36-47.44,11.13-39,5.1-47.8,7.57-71.57"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth8" onClick={() => onToothClick(8)}>
                    <path id="outter8" fill={selected[8] === true ? "green" : "white" }
                          className="outter"
                          d="M2732.4,2890.9c32.14,14.94,64.95,24.43,95,56.65,24.87,19.58,43.37,54.84,47.75,124.88,2.08,49.24,14,97.23-18.58,150.91-28,23.59-10.84,31.61-86.88,71.78-39.17,16.41-67.29,52.59-127.5,31.36-29.13-8.25-39-5-88.86-25.63-21.14-4.81-38.65-16.93-48.39-44.7-10.5-18-19.26-42.9-25.21-79.24,2-31.4,6.77-44.31,11.05-60.38,6.83-42.21,4.27-59.79-.19-72.41-.47-21.26,3.77-42.54,17.07-63.84,7-23,11.58-44.78,52.4-70,37.17-27.94,61.3-49,172.37-19.42Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4384"
                          className="cls-2"
                          d="M2661.91,3297.12c-14.53-6.93-14.69-19-58.66-15.36-10.64-1.38-21.27-1.58-31.94-16.3L2548,3250.87l-19.88-17.19c-15.5-16.86-19.1-35.07-13.93-54.28,2.72-21.3,7.08-47,3.31-50.87-2-13.21,2-34.63,6.75-56.91-2.16-28.76-22.56-24.75,4-105.18,1.74-17.15,10.8-25.92,27.52-25.94,13.61-2.68,17.27-8.95,34.43-22.5,12.19-7.22,18.5-14.42,45.63-21.67,20.7,2.62,41.37-4.26,62.1,12.77l36.3,32.66c29.26,24.25,32.39,37.7,34.61,50.77,8.13,19.52,5,39.07,1.88,58.62-.69,16.66-6.43,30.45-23.18,38,8,9.59,17.08,14.19,20.81,45.63,8.59,54.61-4.62,68.61-13.55,90.56-17.47,25.37-34.95,44.49-52.42,65.65"
                          transform="translate(-100 -100)"/>
                    <path id="path4386"
                          className="cls-2"
                          d="M2613.6,2953.28c15.72,10.72,28,27.41,34.62,54.22-2.83,21-7.68,37.9-12.79,54.35-4.3,22.67-16.15,21.4-10.14,76.75,10.77,10.45,13.43,18.22,10.41,24.11-19.48,41.49-12.69,78.13,18.39,110.29"
                          transform="translate(-100 -100)"/>
                    <path id="path4388"
                          className="cls-2"
                          d="M2584.13,3223.19c9.57-20.79,20.47-40.92,48.14-52.72,3.82-11.76,16.8-16.44,41.35-12.17"
                          transform="translate(-100 -100)"/>
                    <path id="path4390"
                          className="cls-2"
                          d="M2713.08,3084.92c-39.14-3.26-73.53-3.76-85.32,8.84"
                          transform="translate(-100 -100)"/>
                    <path id="path4392"
                          className="cls-2"
                          d="M2529.52,3108.67c30.62-8.5,51.77-25.25,99.09-19.22"
                          transform="translate(-100 -100)"/>
                    <path id="path4394"
                          className="cls-2"
                          d="M2540.63,3070.71l42.3,22.31"
                          transform="translate(-100 -100)"/>
                    <path id="path4396"
                          className="cls-2"
                          d="M2709.38,2990.1c-28.08,5.53-47.72,11-62,16.54"
                          transform="translate(-100 -100)"/>
                    <path id="path4398"
                          className="cls-2"
                          d="M2672.18,2939.33c-11.14,21.87-25.09,43.75-23.1,65.58"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth7" onClick={() => onToothClick(7)}>
                    <path id="outter7" fill={selected[7] === true ? "green" : "white" }
                          className="outter"
                          d="M2526.63,3321.61c-20.13-6.26-42.26,5.89-64.6,20l-30.09,34.56c-21.19,20.17-30.08,40.31-28.29,60.42,1.93,35-7.4,70.14-30.76,105.25-14.94,43.1-24.69,84.74,1.17,116.38,15.79,28.73,35,56.09,73.48,75.67,40.24,16.6,75.72,39.19,124.25,45.37,31.73,2.45,57.49,12.9,108.6-10.63l69.7-46.74c11.74-8.59,22.92-13.4,41.21-66.48,6-49.33,28.95-96.57,1.33-150l-44.23-101.61c-20.92-26.11-34.8-54.81-79.5-72.2-26.06-8.41-43.55-21.52-93.15-17Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4402"
                          className="cls-2"
                          d="M2456.07,3370.07c-10.64,22.16-27.19,44.33-27.42,66.46-5.37,44.65-19.83,75.75-34.2,107-8.4,21.77-21.43,37.92-10.13,83.65,8,28.68,15.5,57.63,57.09,70.54l71.63,31.71c20.41,9.6,48.11,8.2,77.6,4.11,28.71-10.32,57.44-9.88,86.07-50.23,6.17-12.05,9.78-29.16,6.74-59.5"
                          transform="translate(-100 -100)"/>
                    <path id="path4404"
                          className="cls-2"
                          d="M2697.19,3601.34c13.81.16,24.54-4.51,22.34-29.37-3.21-47.21,3.28-95.42-21.06-140.46,0-26.22,1.67-53.51-18.28-66.33-26.41-19.27-39-52.37-94.94-42-27.59,6.13-45.93,6.43-55.17,1"
                          transform="translate(-100 -100)"/>
                    <path id="path4406"
                          className="cls-2"
                          d="M2531.94,3375.05c10.26,25.89,16.55,50.29,41.59,81.79-10.18,33.71-24.75,45.79-39.5,57-3.68,9.51-9.63,4.86-6.75,55.19-3.61,48.28-35.08,64.9-53.2,96.69"
                          transform="translate(-100 -100)"/>
                    <path id="path4408"
                          className="cls-2"
                          d="M2637.68,3590.29c-36.26-17.66-72.52-41.06-108.71-32.47"
                          transform="translate(-100 -100)"/>
                    <path id="path4410"
                          className="cls-2"
                          d="M2438.28,3490c26.61,41.05,58.95,38.3,89.77,47.18"
                          transform="translate(-100 -100)"/>
                    <path id="path4412"
                          className="cls-2"
                          d="M2491.58,3433.77c21.4,24.12,42.62,48.29,41.58,79.21"
                          transform="translate(-100 -100)"/>
                    <path id="path4414"
                          className="cls-2"
                          d="M2679.46,3415.18c-24.6,29.08-58.28,45.52-105.92,42.52"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth6" onClick={() => onToothClick(6)}>
                    <path id="outter6" fill={selected[6] === true ? "green" : "white" }
                          className="outter"
                          d="M2373.48,3766c-84.63,42.06-83.4,64.68-85.95,96.78,6.4,38.44,4.69,52.74,4.49,71.54-1.14,39.52,23.47,49.9,39,70.59,49.74,49.76,76.23,33.68,111.32,42A621.09,621.09,0,0,0,2513,4032c52.62-12.78,48-32.55,68-49.32,25.57-34.32,27.82-78.18,14.31-128.49-11.07-29.92-33.56-52.3-57.09-74l-57.83-29.16c-44.4-18.36-95.27,6.84-106.86,14.93Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4418"
                          className="cls-2"
                          d="M2452.78,3763.19c18.43,3.7,40.76,6.45,48.4,47.29-.15,17.76,5.55,31.59,12.19,44.79,8,42.58-12.93,70.77-31.63,100.08-14.52,24.51-32.49,27-49.92,32.89-19,10.05-33.26,3.31-43.15-18.85-6.05-12.65-7.3-31.33-21.64-33.56-6.48-11.43-7.33-21-7-30.16,8.16-15.3,1.55-36.47-1-56,33.46-1.68,41.69-28.47,45.54-59.6,15.57-5.9,30.51-8,48.2-26.85Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4420"
                          className="cls-2"
                          d="M2412.76,3951.22c-19.85-18-19.49-49.49-19.18-81,12.2-52.8,34.31-78,69.66-66.56"
                          transform="translate(-100 -100)"/>
                    <path id="path4422"
                          className="cls-2"
                          d="M2374.74,3917.7c1.56-27.08,9.81-39.22,20.57-45.74"
                          transform="translate(-100 -100)"/>
                    <path id="path4424"
                          className="cls-2"
                          d="M2424.4,3788.26l-2.52,24.15"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth5" onClick={() => onToothClick(5)}>
                    <path id="outter5" fill={selected[5] === true ? "green" : "white" }
                          className="outter"
                          d="M2388.06,4065.08c33.56,46.28,41.61,79.48,38.21,106.8-10,47.54-15.24,98.56-65.21,117.41-79.76,26.57-96.92,4.52-129.33-5.69q-55.62-27-87.24-67.88c-16.16-11.16-15-22.37-15.61-33.58,2.5-20.82-2-34.87-3.58-51.71-2.44-32.67,11.66-55.48,19.61-82,23.52-19.15,40.74-45.44,79.19-47.62l66.37-1.9c40.89-2.14,68.78,33.32,97.59,66.12Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4430"
                          className="cls-2"
                          d="M2186.62,4176c-2.06,11.44,5.36,24.22-11.12,33.64"
                          transform="translate(-100 -100)"/>
                    <path id="path4432"
                          className="cls-2"
                          d="M2272,4188.66l-73.5-87.74c-15.45-18.66-27.27-23.18-28.42,13.87"
                          transform="translate(-100 -100)"/>
                    <path id="path4434"
                          className="cls-2"
                          d="M2283.86,4110.18c-7.2-22.1-27.45-44.18-62.24-66.21,13.47,4.7,26.91,9.5,44.83.74,21.24-10.41,31.37,1.57,44,8.51"
                          transform="translate(-100 -100)"/>
                    <path id="path4428"
                          className="cls-2"
                          d="M2370.84,4074.61c-11.86,16.68-26.58,36.31-34.36,46.64-4.18,7.38-.65,12.58-1.66,24.15,5,30-4.32,28.07-12.84,34.51-8.69,6.74-17.62,13-24.08,24.2-7.41,22.42-17.94,17.43-27.53,20.76l-39.64,7c-31.44,9.46-36.39-5-53.46-5.9"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth4" onClick={() => onToothClick(4)}>
                    <path id="outter4" fill={selected[4] === true ? "green" : "white" }
                          className="outter"
                          d="M2106.58,4226.17c-57.68-8.11-108.17-8.38-118,35.65l-29.08,86.28c-20.91,65.77,1,52.46,4.5,73.27l43.24,50.75c16.52,16.72,34.32,32.55,73.36,33.42,57.23-2.11,115.15-3,127.36-85.67l4.92-96.57c-11.87-46.14-24.86-91.63-106.29-97.14Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4438"
                          className="cls-2"
                          d="M2177.47,4303.57c-17,53.73-27.58,115.35-79.84,125.21-40.64,18.53-82.19,48.38-116.41-12.63"
                          transform="translate(-100 -100)"/>
                    <path id="path4440"
                          className="cls-2"
                          d="M2000.11,4386.79c20.65,12.28,42.13,26.7,44.8-7.88,3.75-36-11.83-52.64-18.31-78.4"
                          transform="translate(-100 -100)"/>
                    <path id="path4442"
                          className="cls-2"
                          d="M2141.26,4302.8c-4.83-21.5-20.65-37.9-55.29-45.55"
                          transform="translate(-100 -100)"/>
                    <path id="path4444"
                          className="cls-2"
                          d="M2123.29,4351.12c-11.75,11.21-23.56,2.09-35.43-32.66-6.18-26.43-17.12-33.55-27.7-42.17"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth3" onClick={() => onToothClick(3)}>
                    <path id="path4446"
                          className="cls-2"
                          d="M1888.89,4385.36c22.08,32.12,83.29,64.15,14,96.51"
                          transform="translate(-100 -100)"/>
                    <path id="path4448"
                          className="cls-2"
                          d="M1837.26,4419.11l-15.33,74.18c-10,35.71-20.06,21-30.17.08"
                          transform="translate(-100 -100)"/>
                    <path id="path4450"
                          className="cls-2"
                          d="M1967.5,4447.22c-1.57,26.18.87,54.94-31.73,61.29-27.72,9.55-62.5,19.13-73.2,28.64-45.89,25.34-50.17,12.07-68.94,12.25"
                          transform="translate(-100 -100)"/>
                    <path id="outter3" fill={selected[3] === true ? "green" : "white" }
                          className="outter"
                          d="M1799.18,4364c16.39-45.75,56.39-46.28,83.56-24.36l38.92,49,45.81,43.84c6.66,20.53,24.57,19.26,3.69,93.1-12.56,39.94-37.82,57.78-68.8,65.7-39.72,11.4-73.73,4.07-104.34-14.39-20.88-21.5-53.91-31.65-45-80.91,8.85-22.16,9.27-27.34,28.26-69.9,6-20.62,12-30.78,17.94-62.12Z"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth2" onClick={() => onToothClick(2)}>
                    <path id="outter2" fill={selected[2] === true ? "green" : "white" }
                          className="outter"
                          d="M1699.36,4433.27c13,28.54,22.2,60.12,45.9,80,10.64,10.65,19.79,24.29,8,80.15-12.27,23.27-33.27,36.89-66.28,37.24-26.62-5-49.54-12.14-91.4-8.38-20.16-4.87-38.53,1.66-63-30.87-8.09-15.36-20.69-25.15-.2-75.86l62.71-83.78c17-12.68,18.87-32.19,58.53-34.64,31,.94,36.82,19.62,45.78,36.09Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4456"
                          className="cls-2"
                          d="M1740.28,4585.75c-17.11,2.9-33.32,8.45-52.58,2.72l-31,2.67c-21.83,3.23-40.61.3-60.34-.71-29.92-1.56-60.74-2.7-52.65-22.27"
                          transform="translate(-100 -100)"/>
                </g>
                <g id="tooth1" onClick={() => onToothClick(1)}>
                    <path id="outter1" fill={selected[1] === true ? "green" : "white" }
                          className="outter"
                          d="M2702.6,2003.91c-57.48,5.25-109.32,18.93-144.23,50.38-49.84,25.76-81.64,52.76-79,82.13-16,44.76-3.54,77.29,5.81,111.13l26.55,55.57c16.8,32.34,51.51,53.86,105.8,63.57,63.92,10.6,127.84,23.3,191.63-13.06,72.63-27.09,76.09-65.53,83.07-103.39,21.43-38.83,35.78-85.59,27.28-157.93-7.53-33.07-17.1-75.11-64-94.32s-88.9-9.24-152.92,5.92Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4280"
                          className="cls-2"
                          d="M2873.65,2063c53.37,75.73,16.8,109.1-5.24,149.31,6,68.16,4.2,120.56-42,114.19-68,48.49-117.68,22.47-171.79,14.54-7.76-12.32-8.25-28.29-29.67-33.73,0,0-47.65-102.22-53.83-118.17s-11.15-45.51,20.93-77.52c0,0,49.63-51.86,81.51-66.41,15.17-6.92,33-4.83,49.28-8.58,33.3-7.68,98.51-28.43,98.51-28.43,31.25-6.9,50.68,9.28,52.26,54.8Z"
                          transform="translate(-100 -100)"/>
                    <path id="path4282"
                          className="cls-1"
                          d="M2654.47,2269.18c-2-1.35,33.88-24.23,59.07-34,12.88-5,20.58-20.45,23.85-33.87,6.74-27.61-20-62.63-11.49-84.48s26.42-22.82,42.18-29.68c10.55-4.6,16.82-.77,33.78-7.13"
                          transform="translate(-100 -100)"/>
                    <path id="path4284"
                          className="cls-4"
                          d="M2675.13,2091.66c22.45,6.6,51,12.19,52.17,22.39"
                          transform="translate(-100 -100)"/>
                    <path id="path4286"
                          className="cls-1"
                          d="M2799.39,2209.64c-9.08-4.53-20.77-10-35.23-5.54s-22,5.84-26.78-7"
                          transform="translate(-100 -100)"/>
                    <path id="path4288"
                          className="cls-1"
                          d="M2676.83,2201.51c21.14-.87,44.26,11.82,61.94-12.84"
                          transform="translate(-100 -100)"/>
                    <path id="path4458"
                          className="cls-1"
                          d="M2724.81,2074.35c-5.1,12.61-7.82,24.73,1.15,34.48"
                          transform="translate(-100 -100)"/>
                </g>
            </svg>
        </>
    )
}