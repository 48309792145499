import {API_BASE_URL, del, get, post} from "../util/network.util";
import {getClientsResponseDummy, getHistoryResponseDummy} from "./mocks";
import Config from "../config";

export async function getFiles(clientId) {
    if (Config.use_dummy) return getClientsResponseDummy;
    return await get(`/v1/client/${clientId}/file`);
}

export async function deleteFile(fileId) {
    if (Config.use_dummy) return
    return await del(`/v1/file/${fileId}`);
}

export async function addFiles(clientId, filesArray) {
    if (Config.use_dummy) return
    return await post("/v1/file", {
        clientId: clientId,
        files : filesArray
    });
}

async function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve({
                name: file.name,
                type: file.type,
                size: file.size,
                data: reader.result
            });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

export async function readFiles(filesArray) {
    return new Promise((resolve, reject) => {
        Promise
            .all(filesArray.map(file => readFile(file)))
            .then(values => resolve(values))
            .catch(e => reject(e))
    })
}

