import React, {Component} from 'react';
import {Link} from "react-router-dom";
import sidebarStore from "../../store/sidebar.store";
import userStore from "../../store/user.store";
import './sidebar.css'
import CollapseableItem from "./CollapseableItem";

class Sidebar extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            privileges: [],
            image: undefined,
            active: false
        };

        userStore.subscribe(()=>{
            if (this._isMounted && userStore.getState().myprofile) {
                this.setState({
                    username : userStore.getState().myprofile.username,
                    privileges: userStore.getState().myprofile.privileges,
                    image: userStore.getState().myprofile.image
                });
            }
        });

        sidebarStore.subscribe(()=> {
            if (this._isMounted) {
                this.setState({
                    active: sidebarStore.getState().activeSidebar
                });
            }
        });

    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <nav id="sidebar" className={'border-bottom border-dark shadow bg-dark text-light ' + (this.state.active ? 'active' : '' )}>
                <div className="small sidebar-header sidebar-background">
                    <h3>
                        <div className="pt-3 pb-3">
                            <img height="80" src="/img/icons/dental-comfort.svg"/>
                            <div className="pt-2">
                                <small>Dra. Daiana Flores</small>
                            </div>
                        </div>
                    </h3>
                    <strong>
                        <div>
                            <div className="container-logo">
                                <img height="40" src="/img/icons/dental-comfort.svg"/>
                            </div>
                        </div>
                    </strong>
                </div>

                <ul className="list-unstyled components small">

                    <li className="sidebar-item">
                        <Link to="/">
                            <i className="fa fa-home"/>
                            Inicio
                        </Link>
                    </li>

                    <li className="sidebar-item"><Link to="/pacientes"><i className="fa fa-users"/>Pacientes</Link></li>
                    <li className="sidebar-item"><Link to="/agendar"><i className="fa fa-book"/>Agendar</Link></li>

                </ul>

                <ul className="list-unstyled CTAs border-light border-top">
                    <li className="sidebar-item">
                        <a href="http://igmasiri.com" className="download text-center">
                            <div className="pb-3">
                                powered by: <h6>igmasiri</h6>
                            </div>
                        </a>
                    </li>
                    <li className="sidebar-item"></li>
                </ul>
            </nav>
        );
    }
}

export default Sidebar;