import {del, get, post} from "../util/network.util";
import {getHistoryResponseDummy} from "./mocks";
import Config from "../config";

export async function getHistory(historyId) {
    if (Config.use_dummy) return getHistoryResponseDummy;
    return await get(`/v1/history/${historyId}`);

}
export async function deleteHistory(historyId) {
    if (Config.use_dummy) return
    return await del(`/v1/history/${historyId}`);
}

export async function postHistory(clientId, historyId, date, description, treatment, price, payment, teeth) {
    if (Config.use_dummy) return
    let url = "/v1/history"
    if (historyId) url +=  "/" + historyId
    return await post(url, {
        clientId: clientId,
        date : date,
        description : description,
        treatment : treatment,
        price : price,
        payment : payment,
        teeth: teeth
    });
}