import React, {useEffect, useRef} from 'react';
import './login.css'
import {login, logout, setAccessToken} from '../../services/user.service'
import LoadingSpinner from '../../components/loading-spinner'
import {useNavigate} from "react-router-dom";
import Alert from "react-s-alert";
import {useIsMount} from "../../util/custom.hook";

export default function Login() {

    const navigate = useNavigate()
    const isMount = useIsMount();

    const loading = useRef();
    const username = useRef();
    const password = useRef();

    const onSubmitClick = event => {
        event.preventDefault();
        loading.current.show()
        login(username.current.value,password.current.value)
            .then( r => {
                setAccessToken(r.token)
                navigate("/")
            })
            .catch(e => {
                if (e === "InvalidCredentials") {
                    Alert.error("Usuario o contraseña incorrecta. Intenta nuevamente.");
                } else {
                    Alert.error("Ocurrió un error al intentar iniciar session. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const componentDidMount = () => {
        logout()
        loading.current.hide()
    }
    useEffect(componentDidMount, []);

    return(
        <>
            <LoadingSpinner ref={loading} />
            <div className="center-div mb-5 mt-3 container">
                <div className="text-center row">
                    <div className="col-lg-3 col-md-4 col-sm-5 col-6 mx-auto fade-in p-0">
                        <div className="mb-1 square mx-auto logo" style={{backgroundImage: 'url(' + "/img/icons/dental-comfort.svg" + ')'}} />
                        <div className="mb-3 opacity-7">
                            <h5>Dental Comfort</h5>
                            <p>
                                Powered by <b> igmasiri </b>.
                                <br/>
                                Inicia sesion.
                            </p>
                        </div>
                        <form onSubmit={onSubmitClick}>
                            <input className="form-control mb-3" ref={username} placeholder="email" />
                            <input className="form-control mb-3" ref={password} placeholder="password" type="password"/>
                            <button className="w-100 btn btn-primary mx-auto">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}