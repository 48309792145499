import React from 'react';
import ReactDOM from 'react-dom';
import IndexRouter from './routers/IndexRouter';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.override.scss'
import './util/log.util'

ReactDOM.render(<IndexRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
