import {randomUniqueId} from "../../util/id.util";
import React from "react";

const TextInput = ({label, value, valid, onChange, errorMessage, type = "text"}) => {
    const id = randomUniqueId()
    let validity = ''
    //if (valid === true) validity = ' is-valid '
    if (valid === false) validity = ' is-invalid '
    const input = (type === "textarea")
        ? <textarea id={id} value={value} className={"form-control " + validity} onChange={input => onChange(input.target.value)} />
        : <input id={id} value={value} type={type} className={"form-control " + validity} onChange={input => onChange(input.target.value)} />
    return(
        <div className="form-group">
            <label htmlFor={id}>{label}</label>
            {input}
            {errorMessage!==undefined &&  <div className="invalid-feedback"> {errorMessage} </div>}
        </div>
    )
}

export default TextInput