import React, {Component} from 'react';
import LoadingSpinner from "../loading-spinner";

class BasicFormWrapper extends Component{

    constructor(props) {
        super(props);
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.state = {
            hidden: false
        }
    }

    hide(){
        this.refs.child.hide();
    }

    show(){
        this.refs.child.show();
    }

    render(){
        if (!this.state.hidden)
            return(
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card closeable-card shadow border border-dark rounded-0">
                                <LoadingSpinner ref="child" />
                                <div className="card-header d-flex flex-row  align-items-center">
                                    <div className="w-100"> {this.props.title} </div>
                                    {this.props.nav}
                                    <div onClick={()=>this.setState({hidden: true})}> <i className="fas fa-times cursor-pointer p-1" /> </div>
                                </div>
                                <div className="card-body">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                {this.props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else
            return null;
    }
}

export default BasicFormWrapper;