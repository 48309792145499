import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { ACCESS_TOKEN } from '../../util/network.util';
import sidebarStore from '../../store/sidebar.store'
import userStore from '../../store/user.store'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './header.css'

class Header extends Component{

    _isMounted = false;

    constructor(props) {
        super(props);
        this.botonLogoutClick = this.botonLogoutClick.bind(this);
        this.sidebarCollapseClick = this.sidebarCollapseClick.bind(this);
        this.navbarCollapseClick = this.navbarCollapseClick.bind(this);

        this.state = {
            username : ''
        };

        userStore.subscribe(()=>{
            if (this._isMounted && userStore.getState().myprofile) {
                this.setState({
                    username : userStore.getState().myprofile.username
                });
            }
        });
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    botonLogoutClick(event){
        localStorage.removeItem(ACCESS_TOKEN);

    }

    sidebarCollapseClick(){
        sidebarStore.dispatch({type: "TOGGLE_SIDEBAR"});
    }

    navbarCollapseClick(){
        sidebarStore.dispatch({type: "TOGGLE_NAVBAR"});
    }

    render(){
        return(
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" expand="lg" className="border-bottom border-dark shadow">
                <Navbar.Brand>
                    <button type="button" id="sidebarCollapse" onClick={this.sidebarCollapseClick} className="btn btn-primary">
                        <i className="fas fa-align-left"/>
                    </button>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" as="div">
                    <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" >
                        <i className="fas fa-align-justify"></i>
                    </button>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto align-middle">
                        <Nav.Link> <i className="fas fa-user-circle"/> {this.state.username}</Nav.Link>
                       <Link onClick={this.forceUpdate} to={'/login'} className="nav-link" onClick={this.botonLogoutClick}><i className="fas fa-sign-out-alt"/></Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header;