import {del, get, objToQueryString, post} from "../util/network.util";
import {getFreeDatesResponseDummy, getTimesResponseDummy} from "./mocks";
import {ignoreTimezone, toIsoStringNoMillis, toIsoStringNoTime} from "../util/date.util";
import Config from "../config";

export async function getFreeDates(filter) {
    if (Config.use_dummy) return getFreeDatesResponseDummy;
    return await get(`/v1/appointment/free-date?${objToQueryString(filter)}`);
}

export async function getTimesByDate(date) {
    if (Config.use_dummy) return getTimesResponseDummy;
    return await get(`/v1/appointment/times/${toIsoStringNoTime(date)}?ignoreFreeDay=true`);
}

export async function saveSchedule(dates, clientId) {
    return await post("/v1/appointment", {
        data : dates.map( it => ({clientId: clientId, date: toIsoStringNoMillis(ignoreTimezone(it.dateTime))}))
    });
}

export async function deleteSchedule(scheduleId) {
    return await del(`/v1/appointment/${scheduleId}`);
}