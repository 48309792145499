import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Button, Modal} from "react-bootstrap";

const SimpleModal = forwardRef((props, ref) => {

    const [visible, setVisible] = useState(false)
    const [callbackData, setCallbackData] = useState(undefined)

    useImperativeHandle(ref, () => ({
        hide(){
            setVisible(false);
        },
        show(callback = undefined){
            if (callback) {
                setCallbackData(callback)
            }
            setVisible(true);
        }
    }))

    const handleClose = () => {setVisible(false)}
    const onConfirm = () => {
        setVisible(false)
        props.onConfirm(callbackData)
    }

    return (
            <Modal show={visible} onHide={handleClose} backdrop="static" keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Cancelar </Button>
                    <Button variant="primary" onClick={onConfirm}>Aceptar</Button>
                </Modal.Footer>
            </Modal>
    )

})

export default SimpleModal