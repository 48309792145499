import React from "react";

export default function Spinner({size = "inherited" , color = "inherited"}) {
    const sizeContainer = !isNaN(size) ? size + 10 : size
    return(
        <div className="lds-ring" style={{width: sizeContainer, height: sizeContainer}}>
            {[1,2,3,4].map(i => <div key={i} className="border-bottom border-primary" ref={element => {
                if (element) {
                    element.style.setProperty('border-color', color, 'important');
                    element.style.setProperty('width', size+"px", 'important');
                    element.style.setProperty('height', size+"px", 'important');
                }
            }} />)}
        </div>
    )
}