import {createStore} from 'redux';

const reducer = (state,action) =>{

    if (action.type === "TOGGLE_SIDEBAR"){
        return{
            ...state,
            activeSidebar: !state.activeSidebar
        }
    }

    return state;
};

export default createStore(reducer,{
    activeSidebar: false
});