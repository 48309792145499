import React, {useEffect, useRef, useState} from "react";
import BasicWrapper from "../../components/generic-wrapper";
import Calendar from "react-calendar";
import {deleteSchedule, getTimesByDate} from "../../services/schedule.service";
import {Row} from "react-bootstrap";
import SimpleModal from "../../components/modal";
import Alert from "react-s-alert";
import {dateFromString, dateTimeToUyString, ignoreTimezone} from "../../util/date.util";
import {useNavigate} from "react-router-dom";
import {useIsMount} from "../../util/custom.hook";
import {getAccessToken} from "../../services/user.service";


export default function Home() {
    const isMount = useIsMount();

    const loading = useRef()
    const modal = useRef()

    const navigate = useNavigate()

    const [dateSelected, setDateSelected] = useState(new Date());
    const [schedules, setSchedules] = useState([]);

    const fetchData = date => {
        loading.current.show()
        getTimesByDate(date)
            .then( r => {
                setSchedules(r.data.filter(it => it.appointmentId && it.client))
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener las reservas. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const componentDidMount = () => {
        console.log("Home.componentDidMount")
        if (!getAccessToken()) {
            navigate("/login")
            return
        }
        fetchData(dateSelected)
    }
    useEffect(componentDidMount, [])

    const onDateSelected = dateSelected => {
        console.log("Home.onDateSelected")
        setDateSelected(dateSelected)
        fetchData(dateSelected)
    }

    const onRowClick = element => {
        console.log("Home.onRowClick")
        navigate("/paciente/"+element.client.id)
    }

    const onDeleteClick = (element, event) => {
        console.log("Home.onDeleteClick")
        event.stopPropagation()
        modal.current.show(element)
    }

    const onModalConfirm = element => {
        loading.current.show()
        deleteSchedule(element.appointmentId)
            .then( r => {
                fetchData(dateSelected)
                Alert.success("Reserva borrada.");
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar borrar la reserva. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    return (
        <BasicWrapper ref={loading} title="Inicio" >
            <Row>
                <Calendar className="mx-auto mt-5 mb-5" locale="es-ES" onClickDay={onDateSelected} value={dateSelected}/>
            </Row>
            <Row>
                <table className="table table-striped table-hover">
                    <thead className="thead-dark">
                    <tr>
                        <th>Fecha</th>
                        <th>Nombre</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {schedules.map((element, index) =>
                        <tr key={index}  onClick={()=>onRowClick(element)} >
                            <td> {dateTimeToUyString(ignoreTimezone(dateFromString(element.dateTime)))} </td>
                            <td> {element.client.name} </td>
                            <td> <i onClick={event=>onDeleteClick(element, event)} className="fas fa-trash text-danger float-right"/> </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </Row>
            <SimpleModal ref={modal} title="Borrar Reserva" body="Esta seguro que desea borrar la reserva?" onConfirm={onModalConfirm} />
        </BasicWrapper>
    )
}