import React, {useEffect, useRef, useState} from 'react';
import BasicWrapper from "../../components/generic-wrapper";
import './basic-list.css'
import {useIsMount} from "../../util/custom.hook";
import {Link, useNavigate, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {deleteClient, getClient, postClient} from "../../services/client.service";
import Alert from 'react-s-alert';
import TextInput from "../../components/input/text-input";
import DeleteButton from "../../components/generic-wrapper/delete-button";
import {dateFromString, dateTimeToUyString, ignoreTimezone} from "../../util/date.util";
import SimpleModal from "../../components/modal";
import useFormData from "../../hooks/form-hook";
import {getAccessToken} from "../../services/user.service";

export default function ClientForm() {
    let { clientId } = useParams();
    const isMount = useIsMount();
    const loading = useRef();
    const modal = useRef();
    const navigate = useNavigate()

    const [id, setId] = useState(0);

    const {data : name, onDataChange: onNameChange} = useFormData('', value => {
        if (value.length === 0) return "El nombre no puede ser vacío"
        if (value.length > 256) return "El nombre no puede tener mas de 256 caracteres"
    })

    const {data : email, onDataChange: onEmailChange} = useFormData('', value => {
        if (value && value.length > 256) return "El email no puede tener mas de 256 caracteres"
    })

    const {data : telephone, onDataChange: onTelephoneChange} = useFormData('', value => {
        if (value) {
            if (value.length > 0 && !value.match(/^\d+$/)) return "El telefono debe ser un numero"
            if (value.length > 256) return "El telefono no puede tener mas de 32 digitos"
        }
    })

    const {data : address, onDataChange: onAddressChange} = useFormData('', value => {
        if (value && value.length > 256) return "La direccion no puede tener mas de 256 caracteres"
    })

    const {data : age, onDataChange: onAgeChange} = useFormData('', value => {
        if (value && value.length > 0 ) {
            if (!value.match(/^\d+$/)) return "La edad debe ser un numero"
            if (Number(value) < 0) return "La edad debe ser mayor a cero"
            if (value.length > 3) return "La edad no puede tener mas de 3 caracteres"
        }
    })

    const {data : occupation, onDataChange: onOccupationChange} = useFormData('', value => {
        if (value && value.length > 256) return "La profesion no puede tener mas de 256 caracteres"
    })

    const {data : startDate, onDataChange: onStartDateChange} = useFormData('', value => {
        if (value && value.length > 0 ) {
            if (value.length > 0 && !value.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})/)) return "La fecha de inicio de tratamiento tiene un formato inválido"
        }
    })

    const {data : comments, onDataChange: onCommentsChange} = useFormData('', value => {
        if (value.length > 4096) return "La observacion no puede tener mas de 4096 caracteres"
    })

    const [history, setHistory] = useState([]);

    const fetchData = () => {
        loading.current.show()
        getClient(clientId)
            .then( r => {
                setId(r.id)
                onNameChange(r.name)
                onEmailChange(r.email)
                onTelephoneChange(r.phone)
                onAddressChange(r.address)
                onAgeChange(r.age)
                onOccupationChange(r.occupation)
                onStartDateChange(r.startDate)
                onCommentsChange(r.comments)
                setHistory(r.history)
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener el paciente. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    const componentDidMount = () => {
        console.log("ClientForm.componentDidMount")
        if (!getAccessToken()) {
            navigate("/login")
            return
        }
        if (clientId) {
            fetchData()
        } else {
            loading.current.hide()
        }
    }
    useEffect(componentDidMount, []);

    const onSubmit = () => {
        loading.current.show()
        postClient(clientId, name.value, email.value, telephone.value, address.value, age.value, occupation.value, startDate.value, comments.value)
            .then( r => {
                Alert.success("Paciente guardado.");
                navigate("/pacientes")
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar guardar el paciente. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const onRowClick = element => {
        navigate(`/paciente/${clientId}/historia/${element.id}`)
    }

    const clientBalance = (history.reduce((collector, {payment}) => collector + payment, 0) - history.reduce((collector, {price}) => collector + price, 0))

    const onDeleteClick = () => modal.current.show()

    const onModalConfirm = () => {
        loading.current.show()
        deleteClient(clientId)
            .then( r => {
                navigate("/")
                Alert.success("Paciente borrado.");
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar borrar el paciente. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const historySection = clientId ? (<>
            <div className="row">
                <Link to={`/paciente/${clientId}/historia`}  className="btn btn-primary float-right mb-2"><i className="fas fa-plus"/> Nuevo</Link>
                <List data={history} onRowClick={onRowClick}/>
            </div>

            <div className="row mb-3">
                <div className="col-md-3 ml-auto">
                    Saldo del paciente: <span className={clientBalance < 0 ? "text-danger" : "text-success"}>{clientBalance}</span>
                </div>
            </div>
        </>) : null

    return(
        <BasicWrapper ref={loading} title={(clientId ? "Editar" : "Nuevo") + " Paciente"} nav={<DeleteButton onDeleteClick={onDeleteClick}/>} >
            <div className="row mb-3">
                <div className="mb-2 col-md-3">
                    <TextInput label="Nombre*" value={name.value} onChange={onNameChange} valid={name.valid} errorMessage={name.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput label="Correo" value={email.value} onChange={onEmailChange} valid={email.valid} errorMessage={email.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput type="number" label="Telefono" value={telephone.value} onChange={onTelephoneChange} valid={telephone.valid} errorMessage={telephone.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput label="Direccion" value={address.value} onChange={onAddressChange} valid={address.valid} errorMessage={address.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput label="Edad" value={age.value} onChange={onAgeChange} valid={age.valid} errorMessage={age.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput label="Profesion" value={occupation.value} onChange={onOccupationChange} valid={occupation.valid} errorMessage={occupation.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput type="datetime-local" label="Inicio tratamiento" value={startDate.value} onChange={onStartDateChange} valid={startDate.valid} errorMessage={startDate.errorMessage} />
                </div>
                <div className="mb-2 col-md-3">
                    <TextInput type="textarea" label="Observaciones" value={comments.value} onChange={onCommentsChange} valid={comments.valid} errorMessage={comments.errorMessage} />
                </div>
                {
                    clientId &&
                    <div className="mb-2 col-md-3">
                        <div className="form-group">
                            <label>Archivos</label>
                            <div className="text-center">
                                <Link to={`/paciente/${clientId}/archivos`} className="btn btn-primary ml-1 ml-md-3  mb-2">
                                    <div className="display-4">
                                        <i className="fas fa-box-open"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {historySection}
            <div className="mt-3">
                <button type="submit" onClick={() => onSubmit()} className="btn btn-primary float-right ml-1 ml-md-3  mb-2" disabled={(!name.valid || !telephone.valid)}><i className="fas fa-check"/> Guardar</button>
                <Link to="/pacientes"  className="btn btn-secondary float-right"><i className="fas fa-times"/> Cancelar</Link>
            </div>
            <SimpleModal ref={modal} title="Borrar Paciente" body="Esta seguro que desea borrar el paciente?" onConfirm={onModalConfirm} />
        </BasicWrapper>
    );
}

const List = ({data, onRowClick}) => {
    return (
        <table className="table table-striped table-hover">
            <thead className="thead-dark">
            <tr>
                <th> Fecha </th>
                <th> Dientes </th>
                <th> Tratamiento </th>
                <th> Precio </th>
                <th> Pago </th>
            </tr>
            </thead>
            <tbody>
            {data.map( element =>
                <tr key={element.id}  onClick={()=>onRowClick(element)} >
                    <td> {dateTimeToUyString(ignoreTimezone(dateFromString(element.date)))} </td>
                    <td> {element.description} </td>
                    <td> {element.treatment} </td>
                    <td> {element.price} </td>
                    <td> {element.payment} </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}