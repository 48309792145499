import React, {useEffect, useRef, useState} from 'react';
import BasicWrapper from "../../components/generic-wrapper";
import Calendar from 'react-calendar'
import {
    areSameDays,
    dateFromString,
    dateToUyString,
    fixTimezone, timeToUyString,
    toIsoStringNoTime,
    twoDigitString
} from "../../util/date.util";
import {getFreeDates, getTimesByDate, saveSchedule} from "../../services/schedule.service";
import {Button, Col, Container, Row} from "react-bootstrap";
import {getClients} from "../../services/client.service";
import {Link, useNavigate} from "react-router-dom";
import Alert from "react-s-alert";
import Spinner from "../../components/loading-spinner/spinner";
import {useIsMount} from "../../util/custom.hook";
import {getAccessToken} from "../../services/user.service";
import SimpleModal from "../../components/modal";
import ClientFormModal from "./client-form";

export default function Schedule() {
    const navigate = useNavigate()
    const [dateSelected, setDateSelected] = useState(undefined);
    const [timeSelected, setTimeSelected] = useState([]);
    const [clientSelected, setClientSelected] = useState(undefined);
    const onDateSelected = dateSelected => {
        console.log("Schedule.onDateSelected")
        setClientSelected(undefined)
        setTimeSelected([])
        setDateSelected(dateSelected)
    }
    const onTimeSelected = timeSelected => {
        console.log("Schedule.onTimeSelected")
        setClientSelected(undefined)
        setTimeSelected(timeSelected)
    }
    const onClientSelected = clientSelected => {
        console.log("Schedule.onClientSelected")
        setClientSelected(clientSelected)
    }
    const componentDidMount = () => {
        console.log("Schedule.componentDidMount")
        if (!getAccessToken()) {
            navigate("/login")
            return
        }
    }
    useEffect(componentDidMount, [])
    return (
        <>
            <DateSelector onDateSelected={onDateSelected} />
            {dateSelected && <TimeSelector onTimeSelected={onTimeSelected} dateSelected={dateSelected} />}
            {dateSelected && timeSelected.length > 0 && <ClientSelector dateTimeSelected={timeSelected} onClientSelected={onClientSelected} />}
            {dateSelected && timeSelected.length > 0 && clientSelected && <Confirm dateTimeSelected={timeSelected} clientSelected={clientSelected} />}
        </>
    )
}

const DateSelector = ({onDateSelected}) => {
    const navigate = useNavigate()
    const isMount = useIsMount();
    const loading = useRef()

    const currentDate = new Date()
    const from = currentDate
    const to = new Date(currentDate)
    to.setMonth(to.getMonth() + 3)

    const [freeDates, setFreeDates] = useState([]);
    const componentDidMount = () => {
        console.log("DateSelector.componentDidMount")
        loading.current.show()
        getFreeDates({from: toIsoStringNoTime(from), to: toIsoStringNoTime(to)})
            .then( r => {
                setFreeDates(r.data.map(it => dateFromString(it.date)))
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener las fechas disponibles. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    useEffect(componentDidMount, [])
    const isDateDisabled = ({activeStartDate, date, view}) =>  freeDates.some(freeDate => areSameDays(freeDate, date))
    return (
        <BasicWrapper ref={loading} title="Seleccion de fecha">
            <Calendar className="mx-auto mt-5 mb-5" minDate={from} maxDate={to} locale="es-ES" onClickDay={onDateSelected} tileDisabled={isDateDisabled} />
        </BasicWrapper>
    )
}

const TimeSelector = ({dateSelected, onTimeSelected}) => {
    const navigate = useNavigate()
    const isMount = useIsMount();
    const loading = useRef()
    const [freeDateTimes, setDateTimes] = useState([]);
    const [timeSelected, setTimeSelected] = useState([]);
    const fetchData = () => {
        loading.current.show()
        getTimesByDate(dateSelected)
            .then( r => {
                setDateTimes(r.data.map(it => ({dateTime : fixTimezone(dateFromString(it.dateTime)) , appointmentId: it.appointmentId, client : it.client})))
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener las horas disponibles. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    const onDateSelectedChange = () => {
        console.log("TimeSelector.onDateSelectedChange")
        setTimeSelected([])
        fetchData()
    }
    useEffect(onDateSelectedChange, [dateSelected])
    const onTimeClick = dateTimeSelected => {
        console.log("TimeSelector.onTimeClick")
        if (!dateTimeSelected.appointmentId) {
            const selectedArray = (timeSelected.some(it=> it.dateTime.getTime() === dateTimeSelected.dateTime.getTime())) // if is selected
                ? timeSelected.filter(it=>it.dateTime.getTime()!==dateTimeSelected.dateTime.getTime()) // unselect
                : [...timeSelected, dateTimeSelected] // else select
            setTimeSelected(selectedArray)
            onTimeSelected(selectedArray)
        }
    }
    return (
        <BasicWrapper ref={loading} title={`Seleccion de hora para la fecha ${toIsoStringNoTime(dateSelected)}`}>
            <Container>
                <Row>
                    {freeDateTimes.map((it,i) => {
                        return (<Col xs={4} md={3} key={i}>
                            <Button onClick={()=>onTimeClick(it)}
                                    className={ 'm-3 '
                                        + (timeSelected.some(selected => selected.dateTime.getTime() === it.dateTime.getTime()) ? 'btn-success' : '')
                                        + (it.appointmentId ? 'btn-danger' : '')} >
                                { twoDigitString(it.dateTime.getHours()) + ":" + twoDigitString(it.dateTime.getMinutes())}
                            </Button>
                        </Col>)
                    }

                    )}
                </Row>
            </Container>
        </BasicWrapper>
    )
}

const ClientSelector = ({dateTimeSelected, onClientSelected}) => {
    const navigate = useNavigate()
    const isMount = useIsMount();
    const loading = useRef()
    const modal = useRef();

    const [name, setName] = useState("")
    const [clients, setClients] = useState([])
    const [clientSelected, setClientSelected] = useState(undefined)

    const onFilterSubmit = () => {
        loading.current.show()
        getClients({name: name, page: 1, size: 10, order: "name", sort: "ASC"})
            .then( r => {
                setClientSelected(undefined)
                setClients(r.data)
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener los pacientes. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const componentDidMount = () => {
        console.log("ClientSelector.componentDidMount")
        loading.current.hide()
    }
    useEffect(componentDidMount, [])

    const onDateTimeSelectedChange = () => {
        console.log("ClientSelector.onDateTimeSelectedChange")
        setName("")
        setClients([])
        onClientClick(undefined)
    }
    useEffect(onDateTimeSelectedChange, [dateTimeSelected])

    const onClientClick = it => {
        console.log("ClientSelector.onClientClick")
        setClientSelected(it)
        onClientSelected(it)
    }

    const onModalConfirm = (client) => {
        setClients([client])
        setClientSelected(client)
        onClientSelected(client)
    }
    const onNewClientClick = () => modal.current.show()

    const handleKeyDown = event => {
        if(event.keyCode === 13){ // on Enter press
            onFilterSubmit()
        }
    }

    return (
        <BasicWrapper ref={loading} title="Seleccion de paciente">
            <div className="row mb-3">
                <div className="mb-2 col-md-2">
                    <input type="text" className="form-control" placeholder="Nombre" value={name} onChange={input => setName(input.target.value)} onKeyDown={handleKeyDown}/>
                </div>
                <div className="col-md-1 ml-auto">
                    <button type="submit" className="btn btn-secondary float-right" onClick={() => onFilterSubmit()}><i className="fa fa-search"/></button>
                </div>
            </div>
            <div className="row mb-3">
                <table className="table table-striped table-hover">
                    <thead className="thead-dark">
                    <tr>
                        <th>Nombre</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map( it =>
                        <tr key={it.id} onClick={()=>onClientClick(it)} className={clientSelected?.id === it.id ? "bg-success text-white" : ''} >
                            <td> {it.name} </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div onClick={onNewClientClick} className="btn btn-primary float-right ml-3 mb-2"><i className="fas fa-plus"/> Nuevo</div>
            <ClientFormModal ref={modal} onConfirm={onModalConfirm} />
        </BasicWrapper>
    )
}

const Confirm = ({dateTimeSelected, clientSelected}) => {
    const isMount = useIsMount();
    const loading = useRef()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const componentDidMount = () => {
        console.log("Confirm.componentDidMount")
        loading.current.hide()
    }
    useEffect(componentDidMount, [])
    const onConfirm = () => {
        console.log("Confirm.onConfirm")
        setIsLoading(true)
        let willUnmount = false
        saveSchedule(dateTimeSelected, clientSelected.id)
            .then( r => {
                Alert.success("Reserva guardada.");
                navigate("/")
                willUnmount = true
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                    willUnmount = true
                } else {
                    Alert.error("Ocurrió un error al guardar la reserva. Intenta nuevamente.");
                }
            })
            .finally(f => {
                //at this point component keep mounted but as set state is async will throw an error.
                if (isMount && !willUnmount) setIsLoading(false)
            })
    }
    return (
        <BasicWrapper ref={loading} title="Confirmar">
            <div className="col-md-12">
                {dateTimeSelected.map(it =>
                    <React.Fragment key={it.dateTime.getTime()}>
                        <h5> Día: {dateToUyString(it.dateTime)} </h5>
                        <h5> Hora: {timeToUyString(it.dateTime)} </h5>
                    </React.Fragment>
                )}
                <h5> Paciente: {clientSelected.name} </h5>
            </div>
            <div className="col-md-12 text-center">
                <button className="btn btn-success mx-auto d-flex align-items-center" disabled={isLoading} onClick={onConfirm} >
                    {isLoading && <Spinner size={20} color={"white"} /> }
                    <span className={isLoading ? "pl-3" : ""}>Confirmar</span>
                </button>
            </div>

        </BasicWrapper>
    )
}