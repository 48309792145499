import React, {forwardRef, useImperativeHandle, useState} from 'react';
import './loading-spinner.css'
import { Fade } from 'react-bootstrap';
import {randomUniqueId} from "../../util/id.util";
import Spinner from "./spinner";

const LoadingSpinner = forwardRef((props, ref) => {

    const id = randomUniqueId("prefix");
    const [visible, setVisible] = useState(props.visible ?? true);

    useImperativeHandle(ref, () => ({
        hide(){
            setVisible(false);
        },
        show(){
            setVisible(true);
        }
    }));

    return (
        <Fade in={visible} unmountOnExit={true}>
            <div id={id} className={props.fullScreen ? "loading-screen" : "loading-component"}>
                <div className={props.fullScreen ? "loading-spinner" : "loading-spinner-component"} >
                    <Spinner />
                </div>
            </div>
        </Fade>
    );

});

export default LoadingSpinner;