import {ACCESS_TOKEN, API_BASE_URL} from "../util/network.util";
import {sendConsoleLog} from "./log.service";

export async function login(username,password) {
    let response
    try{
        response = await fetch(API_BASE_URL + "/v1/login", {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username" : username,
                "password" : password
            })
        });
    } catch(error) {
        console.log(`Network Error: ${error}`)
        sendConsoleLog()
        return await Promise.reject(error);
    }
    if (response.status === 200) {
        return response.json()
    } else if (response.status === 400) {
        return await Promise.reject("InvalidCredentials");
    } else {
        const error = await response.json()
        console.log(`Server Error: ${error}`)
        sendConsoleLog()
        return await Promise.reject(error);
    }
}

export function logout(){
    localStorage.removeItem(ACCESS_TOKEN);
}

export function getAccessToken(){
    return localStorage.getItem(ACCESS_TOKEN);
}

export function setAccessToken(token){
    return localStorage.setItem(ACCESS_TOKEN, token);
}