import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useIsMount} from "../../util/custom.hook";
import "react-datepicker/dist/react-datepicker.css";
import {postClient} from "../../services/client.service";
import Alert from 'react-s-alert';
import TextInput from "../../components/input/text-input";
import useFormData from "../../hooks/form-hook";
import {Button, Modal} from "react-bootstrap";
import LoadingSpinner from "../../components/loading-spinner";


const ClientFormModal = forwardRef((props, ref) => {
    const isMount = useIsMount();
    const loading = useRef();
    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, () => ({
        hide(){
            setVisible(false);
        },
        show(){
            setVisible(true);
        }
    }))

    const handleClose = () => {setVisible(false)}

    const {data : name, onDataChange: onNameChange} = useFormData('', value => {
        if (value.length === 0) return "El nombre no puede ser vacío"
        if (value.length > 256) return "El nombre no puede tener mas de 256 caracteres"
    })

    const {data : email, onDataChange: onEmailChange} = useFormData('', value => {
        if (value && value.length > 256) return "El email no puede tener mas de 256 caracteres"
    })

    const {data : telephone, onDataChange: onTelephoneChange} = useFormData('', value => {
        if (value) {
            if (value.length > 0 && !value.match(/^\d+$/)) return "El telefono debe ser un numero"
            if (value.length > 256) return "El telefono no puede tener mas de 32 digitos"
        }
    })

    const {data : address, onDataChange: onAddressChange} = useFormData('', value => {
        if (value && value.length > 256) return "La direccion no puede tener mas de 256 caracteres"
    })

    const {data : age, onDataChange: onAgeChange} = useFormData('', value => {
        if (value && value.length > 0 ) {
            if (!value.match(/^\d+$/)) return "La edad debe ser un numero"
            if (Number(value) < 0) return "La edad debe ser mayor a cero"
            if (value.length > 3) return "La edad no puede tener mas de 3 caracteres"
        }
    })

    const {data : occupation, onDataChange: onOccupationChange} = useFormData('', value => {
        if (value && value.length > 256) return "La profesion no puede tener mas de 256 caracteres"
    })

    const {data : startDate, onDataChange: onStartDateChange} = useFormData('', value => {
        if (value && value.length > 0 ) {
            if (value.length > 0 && !value.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})/)) return "La fecha de inicio de tratamiento tiene un formato inválido"
        }
    })

    const {data : comments, onDataChange: onCommentsChange} = useFormData('', value => {
        if (value.length > 4096) return "La observacion no puede tener mas de 4096 caracteres"
    })

    const onSubmit = () => {
        loading.current.show()
        postClient(null, name.value, email.value, telephone.value, address.value, age.value, occupation.value, startDate.value, comments.value)
            .then( r => {
                Alert.success("Paciente guardado.");
                setVisible(false)
                props.onConfirm(r.client)
            })
            .catch(e => {
                    Alert.error("Ocurrió un error al intentar guardar el paciente. Intenta nuevamente.");
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    return (
        <Modal show={visible} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
            <Modal.Header>
                <Modal.Title>Nuevo Paciente</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <LoadingSpinner ref={loading} visible={false} />

                <div className="row mb-3">
                    <div className="mb-2 col-md-3">
                        <TextInput label="Nombre*" value={name.value} onChange={onNameChange} valid={name.valid} errorMessage={name.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput label="Correo" value={email.value} onChange={onEmailChange} valid={email.valid} errorMessage={email.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput type="number" label="Telefono" value={telephone.value} onChange={onTelephoneChange} valid={telephone.valid} errorMessage={telephone.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput label="Direccion" value={address.value} onChange={onAddressChange} valid={address.valid} errorMessage={address.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput label="Edad" value={age.value} onChange={onAgeChange} valid={age.valid} errorMessage={age.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput label="Profesion" value={occupation.value} onChange={onOccupationChange} valid={occupation.valid} errorMessage={occupation.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput type="datetime-local" label="Inicio tratamiento" value={startDate.value} onChange={onStartDateChange} valid={startDate.valid} errorMessage={startDate.errorMessage} />
                    </div>
                    <div className="mb-2 col-md-3">
                        <TextInput type="textarea" label="Observaciones" value={comments.value} onChange={onCommentsChange} valid={comments.valid} errorMessage={comments.errorMessage} />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Cancelar </Button>
                <Button variant="primary" disabled={(!name.valid || !telephone.valid)} onClick={onSubmit}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )

})

export default ClientFormModal