import {getAccessToken} from '../services/user.service';
import Config from "../config";
import {sendConsoleLog} from "../services/log.service";

export const API_BASE_URL = Config.api_base_url;
export const ACCESS_TOKEN = 'accessToken';

export async function get(url) {
     return await performHttpRequest({method: 'GET', url: API_BASE_URL + url});
}
export async function del(url) {
    return await performHttpRequest({method: 'DELETE', url: API_BASE_URL + url});
}
export async function post(url, body) {
    return await performHttpRequest({method: 'POST', url: API_BASE_URL + url, body: body, headers: {'Content-Type': 'application/json'}});
}

async function performHttpRequest({method, url, body, headers}) {
    let logMessage = `Method: ${method} - Url: ${url}`
    if (body) logMessage += ` - Body: ${JSON.stringify(body)}`
    let token = getAccessToken();
    if (token) {
        if (headers) {
            headers["Authorization"] = `Bearer ${token}`
        } else {
            headers = { "Authorization" : `Bearer ${token}` }
        }
    }
    if (headers) logMessage += ` - Headers: ${JSON.stringify(headers)}`
    console.log(logMessage)
    const config = {method: method}
    if (body) config.body = JSON.stringify(body)
    if (headers) config.headers = headers
    let response
    try{
        response = await fetch(url,config)
    } catch(error) {
        console.log(`Network Error: ${error}`)
        sendConsoleLog()
        await Promise.reject(error);
    }
    if (response.status === 200) {
        const result = await response.json()
        console.log(`Status: ${response.status} - Body: ${JSON.stringify(result)}`);
        return result
    } else if (response.status === 400) {
        await Promise.reject(response.json());
    } else if (response.status === 401 || response.status === 403) {
        await Promise.reject("Unauthorized");
    } else {
        const error = await response.json()
        console.log(`Server Error: Status ${response.status} - Message ${error}`)
        sendConsoleLog()
        await Promise.reject(error);
    }
}

export function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== "") keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}
