//fix render call field listener
import {useEffect, useRef} from "react";

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountRef.current = false;
        }
    }, []);
    return isMountRef.current;
};