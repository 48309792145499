import React, {useEffect, useRef, useState} from "react";
import BasicWrapper from "../../components/generic-wrapper";
import {addFiles, deleteFile, getFiles, readFiles} from "../../services/file.service";
import {useNavigate, useParams} from "react-router-dom";
import SimpleModal from "../../components/modal";
import Alert from "react-s-alert";
import {useIsMount} from "../../util/custom.hook";
import {getAccessToken} from "../../services/user.service";

const FileList = () => {
    const isMount = useIsMount();
    const loading = useRef();
    const modal = useRef()
    const navigate = useNavigate()
    let { clientId } = useParams();
    const [files, setFiles] = useState([]);
    const onRowClick = element => {
        console.log(element)
        window.open(element.url, "_blank")
    }
    const onDeleteClick = (element, event) => {
        console.log("FileList.onDeleteClick")
        event.stopPropagation()
        modal.current.show(element)
    }
    const fetchData = () => {
        loading.current.show()
        getFiles(clientId)
            .then( r => {
                setFiles(r.files)
                loading.current.hide()
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener los archivos. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    const onModalConfirm = element => {
        loading.current.show()
        deleteFile(element.id)
            .then( r => {
                fetchData()
                Alert.success("Archivo borrado.");
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar borrar el archivo. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    const componentDidMount = () => {
        console.log("FileList.componentDidMount")
        if (!getAccessToken()) {
            navigate("/login")
            return
        }
        fetchData()
    }
    useEffect(componentDidMount, [])

    useEffect(componentDidMount, []);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const onFilesSelected = filesArray => setSelectedFiles(filesArray)
    const onSubmit = () => {
        loading.current.show()
        addFiles(clientId, selectedFiles)
            .then( r => {
                Alert.success("Archivo guardado.");
                navigate(`/paciente/${clientId}`)
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar guardar el archivo. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }
    return (
        <BasicWrapper ref={loading} title="Archivos" >
            <UploadFile onFilesSelected={onFilesSelected} />
            <table className="table table-striped table-hover">
                <thead className="thead-dark">
                <tr>
                    <th> Archivo </th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {files.map( element =>
                    <tr key={element.id}  onClick={()=>onRowClick(element)} >
                        <td> {element.name} </td>
                        <td>  <i onClick={event=>onDeleteClick(element, event)} className="fas fa-trash text-danger float-right" /> </td>
                    </tr>
                )}
                </tbody>
            </table>
            <button type="submit" onClick={() => onSubmit()} className="btn btn-primary float-right ml-1 ml-md-3  mb-2"><i className="fas fa-check"/> Guardar</button>
            <button type="submit" onClick={() => navigate(`/paciente/${clientId}`)} className="btn btn-secondary float-right" ><i className="fas fa-times"/> Atras</button>
            <SimpleModal ref={modal} title="Borrar Archivo" body="Esta seguro que desea borrar el archivo?" onConfirm={onModalConfirm} />
        </BasicWrapper>
    );
}

const UploadFile = ({onFilesSelected}) => {
    const uploadFileButton = useRef()
    const [files, setFiles] = useState([]);
    const onInputFileChange = async event => {
        const filesArray = Array.from(event.target.files)
        const result = await readFiles(filesArray)
        setFiles(result)
        onFilesSelected(result)
    }

    return (
        <>
            <button onClick={()=> uploadFileButton.current.click() } className="btn btn-primary mb-2"><i className="fas fa-upload"/> Subir</button>
            <input ref={uploadFileButton} type="file" style={{display: "none"}} onChange={onInputFileChange} multiple/>
            {files.map((element, index) =>
                <h6 key={index}> {element.name} </h6>
            )}
        </>
    )
}

export default FileList