import {useState} from "react";

const useFormData = (initValue, validate) => {
    const [data, setData] = useState({
        value: initValue,
        valid: undefined,
        errorMessage: undefined
    });

    const onDataChange = (value) => {
        let errorMessage = validate(value)
        let valid = (errorMessage === null || errorMessage === undefined)
        setData({...data, value: value??initValue, valid: valid, errorMessage: errorMessage})
    }

    return {data, onDataChange}
}

export default useFormData;