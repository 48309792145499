import {del, get, objToQueryString, post} from "../util/network.util";
import {getClientResponseDummy, getClientsResponseDummy, postClientResponseDummy} from "./mocks";
import Config from "../config";

export async function getClients(filter) {
    if (Config.use_dummy) return getClientsResponseDummy;
    const queryString = objToQueryString({
        ...filter,
        ...(filter.name ? {name: filter.name} : null),
    });
    return await get(`/v1/client?${queryString}`);
}

export async function getClient(clientId) {
    if (Config.use_dummy) return getClientResponseDummy;
    return await get(`/v1/client/${clientId}`);
}

export async function deleteClient(clientId) {
    if (Config.use_dummy) return
    return await del(`/v1/client/${clientId}`);
}

export async function postClient(clientId, name, email, telephone, address, age, occupation, startDate, comments) {
    if (Config.use_dummy) return postClientResponseDummy
    let url = `/v1/client`
    if (clientId) url +=  "/" + clientId
    return await post(url, {
        name : name,
        email : email,
        phone : telephone,
        address : address,
        age : age,
        occupation : occupation,
        startDate : startDate,
        comments : comments,
    });
}