import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from '../modules/login'
import Dashboard from './Dashboard'
import NotFound from '../modules/error/404'
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import {getConfiguration} from "../services/configuration.service";
import Config from "../config";

export default function IndexRouter() {

    const componentDidMount= ()=> {
        getConfiguration()
            .then( r => {
                if(Config.current_version < r.version) {
                    if('caches' in window){
                        caches.keys().then((names) => {
                            // Delete all the cache files
                            names.forEach(name => {
                                caches.delete(name);
                            })
                        });
                        // Makes sure the page reloads. Changes are only visible after you refresh.
                        window.location.reload(true);
                    }
                }
            })
            .catch(e => {
                Alert.error("Ocurrió un error al conectarse con el servidor. Recarga la página.");
            })
    };
    useEffect(componentDidMount, [])

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/login" element={<Login/>}/>
                    {/*<PrivateRoute path="/" component={Dashboard}/>*/}
                    <Route path="/*" element={<Dashboard/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
            <Alert stack={{limit: 3}} timeout = {3000} position='top-right' effect='slide' offset={65} />
        </>
    );
}