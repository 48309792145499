import {API_BASE_URL} from "../util/network.util";

export function sendConsoleLog() {
    fetch(API_BASE_URL + "/v1/log", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(console.everything)
    })
        .then(r => console.log("Log data sent"))
        .catch(e => console.log("Unable to send log data"))
}