import React, {useContext, useEffect, useRef, useState} from 'react';
import BasicWrapper from "../../components/generic-wrapper";
import Pagination from "../../components/pagination";
import './basic-list.css'
import {useIsMount} from "../../util/custom.hook";
import {Link, useNavigate} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {getClients} from "../../services/client.service";
import Alert from "react-s-alert";
import {getAccessToken} from "../../services/user.service";

const DEFAULT_ROWS_SHOWN = 5

const ViewContext = React.createContext({
    page: 1,
    size: DEFAULT_ROWS_SHOWN,
    sort: 'DESC',
    order: 'name',
    name: ''
});

export default function ClientList() {
    const isMount = useIsMount();
    const loading = useRef();
    const context = useContext(ViewContext);
    const [viewState, setViewState] = useState(context)
    const navigate = useNavigate()

    const [response, setResponse] = useState({data:[], total:0});

    const fetchData = () => {
        loading.current.show()
        getClients(viewState)
            .then( r => {
                setResponse(r);
            })
            .catch(e => {
                if (e === "Unauthorized") {
                    navigate("/login")
                } else {
                    Alert.error("Ocurrió un error al intentar obtener los pacientes. Intenta nuevamente.");
                }
            })
            .finally(f => {
                if (isMount) {
                    if (loading.current) {
                        loading.current.hide()
                    }
                }
            })
    }

    const updateFilterState = params => setViewState({
        ...viewState,
        ...params,
    });

    const filterSubmit = (filter) => updateFilterState({...filter})
    const headerClick = ({by, order}) => updateFilterState({ sort: by,order: order})
    const pageClick = event => updateFilterState({page: event.currentPage})
    const rowClick = element =>  navigate("/paciente/"+element.id)

    const componentDidMount = () => {
        console.log("ClientList.componentDidMount")
        if (!getAccessToken()) {
            navigate("/login")
            return
        }
        fetchData()
    }
    useEffect(componentDidMount, [])
    const onViewStateChange = () => {if (isMount) fetchData()}

    useEffect(componentDidMount, []);
    useEffect(onViewStateChange, [viewState]);

    return(
        <ViewContext.Provider value={{...viewState}}>
            <BasicWrapper ref={loading} title="Lista de Pacientes">
                <Filter  onSubmit={filterSubmit}/>
                <List data={response.data} onRowClick={rowClick} onHeaderClick={headerClick}/>
                <Page total={response.total} onPageClick={pageClick} />

                <Link to="/paciente"  className="btn btn-primary float-right ml-3 mb-2"><i className="fas fa-plus"/> Nuevo</Link>
                <Link to="/"  className="btn btn-secondary float-right"><i className="fas fa-arrow-left"/> Atras</Link>
            </BasicWrapper>
        </ViewContext.Provider>

    );
}

const Filter = ({onSubmit}) => {
    const context = useContext(ViewContext);

    const [name, setName] = useState(context.name);
    const [size, setSize] = useState(context.size);

    useEffect(() => {
        setName(context.name);
        setSize(context.size);
    }, [context]);

    const handleKeyDown = event => {
        if(event.keyCode === 13){ // on Enter press
            onSubmit({size,name, page: 1})
        }
    }

    return(
        <div className="row mb-3">
            <div className="mb-2 col-md-1">
                <select className="form-control" value={size} onChange={input=>setSize(Number(input.target.value))}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                </select>
            </div>
            <div className="mb-2 col-md-2">
                <input type="text" className="form-control" placeholder="Nombre" value={name} onChange={input => setName(input.target.value)} onKeyDown={handleKeyDown}  />
            </div>
            <div className="col-md-1 ml-auto">
                <button type="submit" className="btn btn-secondary float-right" onClick={() => onSubmit({size,name, page: 1})}><i className="fa fa-search"/></button>
            </div>
        </div>
    )
}

const List = ({data, onRowClick, onHeaderClick}) => {
    const isMount = useIsMount();
    const [sort, setSort] = useState({by: '', order: ''});
    const onSortChange = sortField => setSort({by: sortField, order: sort.order === "asc" ? "desc" : "asc"})
    useEffect(() => { if (!isMount) onHeaderClick(sort) }, [sort]) // set state callback (is a sort listener change)
    // onSortChange.sortFieldId must be equals Sort.name in order to re draw arrows correctly
    return (
        <table className="table table-striped table-hover">
            <thead className="thead-dark">
            <tr>
                <th onClick={()=>onSortChange('name')} className="cursor-pointer" scope="col">
                    Nombre <Sort name="name"/>
                </th>
                <th>Correo</th>
                <th>Phone</th>
            </tr>
            </thead>
            <tbody>
            {data.map((element, index) =>
                <tr key={index}  onClick={()=>onRowClick(element)} >
                    <td> {element.name} </td>
                    <td> {element.email} </td>
                    <td> {element.phone} </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}

const Sort = ({name}) => {
    const context = useContext(ViewContext);
    if (name === context.sort) return (context.order === "asc") ? (<i className="fas fa-sort-up"/>) : (<i className="fas fa-sort-down"/>)
    return (<i className="fas fa-sort"/>)
}

const Page = ({total, onPageClick}) => {
    const context = useContext(ViewContext);
    return (
        <div className="d-flex flex-row py-4 align-items-center">
            <Pagination key={`pagination-${total}${context.size}`} initPage={context.page} totalRecords={total} pageLimit={context.size} pageNeighbours={1} onPageChanged={onPageClick}/>
        </div>
    ); // we use key in order to update Pagination component when some of those values changes
}