export function ignoreTimezone(date){
    if (!isNaN(date)) date = new Date(date);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
}

export function areSameDays(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

export const fixTimezone = date => new Date(date.getTime() - ((new Date()).getTimezoneOffset() * 60000)) //offset in milliseconds

export const toIsoStringNoMillis = date => date.toISOString().split('.').shift()
export const toIsoStringNoTime = date => date.toISOString().split('T').shift()

export const dateFromString = s => {
    const d = new Date(s)
    return new Date(d.getTime() + (d.getTimezoneOffset() * 60000));
}

export const dateToUyString = d => `${twoDigitString(d.getDate())}/${twoDigitString(d.getMonth()+1)}/${d.getFullYear()}`
export const timeToUyString = d => `${twoDigitString(d.getHours())}:${twoDigitString(d.getMinutes())}`

export const dateTimeToUyString = d => `${twoDigitString(d.getDate())}/${twoDigitString(d.getMonth()+1)}/${d.getFullYear()} ${twoDigitString(d.getHours())}:${twoDigitString(d.getMinutes())}`



export const twoDigitString = number => `${number<10?'0':''}${number}`