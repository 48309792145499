import React, {Component} from 'react';
import sidebarStore from '../store/sidebar.store'
import Sidebar from '../components/sidebar'
import Header from '../components/header'
import LoadingSpinner from '../components/loading-spinner'
import Home from '../modules/home'
import {Route, Routes} from "react-router-dom";
import NotFound from "../modules/error/404";
import ClientList from "../modules/client/list";
import ClientForm from "../modules/client/form";
import HistoryForm from "../modules/history/form";
import Schedule from "../modules/schedule";
import FileList from "../modules/files";

class Dashboard extends Component{

    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            sidebarPrevStatus : false,
            sidebarIsChanging : false,
            pageIsLoading: true,
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let component = this;
        component.setState({pageIsLoading: false});

        component.refs.child.hide();

        //comentar para desactivar el ocultar contenido en animacion de sidebar
        sidebarStore.subscribe(()=>{
            if (this._isMounted) {
                this.setState({
                    sidebarPrevStatus: sidebarStore.getState().activeSidebar,
                    sidebarIsChanging: this.state.sidebarPrevStatus!==sidebarStore.getState().activeSidebar
                });
                setTimeout(function(){
                    this.setState({sidebarIsChanging:false});
                }.bind(this),300); //css transition timeout
            }
        });
    }

    render(){
        return(
         <div>
             <LoadingSpinner ref="child" fullScreen="true" />
                 <div className="wrapper">
                     <Sidebar/>
                     <div id="content">
                         <Header/>
                         <div id="page-content"
                              className={"p-md-5 pt-3 p-0 " + (this.state.sidebarIsChanging ? 'sidebar-is-changing' : '')}>
                             {!this.state.pageIsLoading &&
                                <Routes>
                                    <Route path="/" element={<Home/>} />

                                    <Route path="/pacientes" element={<ClientList/>} />
                                    <Route path="/paciente" element={<ClientForm/>} />
                                    <Route path="/paciente/:clientId" element={<ClientForm/>} />
                                    <Route path="/paciente/:clientId/archivos" element={<FileList/>} />
                                    <Route path="/paciente/:clientId/historia" element={<HistoryForm/>} />
                                    <Route path="/paciente/:clientId/historia/:historyId" element={<HistoryForm/>} />

                                    <Route path="/agendar" element={<Schedule/>} />

                                    <Route path="*" element={<NotFound/>}/>
                                </Routes>
                             }
                         </div>
                     </div>
                 </div>
         </div>
        );
    }

}

export default Dashboard;